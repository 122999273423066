import { lazy } from 'react';

export * as Report from './report';
export * as Dashboards from './dashboards';

export const ActivityLogging = lazy(() => import('./ActivityLogging'));
export const Tiers = lazy(() => import('./tiers/Tiers'));
export const TierCreate = lazy(() => import('./tiers/TierCreate'));
export const TierUsers = lazy(() => import('./tiers/Users'));
export const TierWallets = lazy(() => import('./tiers/Wallets'));
export const TierCategories = lazy(() => import('./tiers/Categories'));
export const Categories = lazy(() => import('./product/Categories'));
export const Products = lazy(() => import('./product/Products'));
export const Product = lazy(() => import('./product/Product'));
export const ProductDetail = lazy(() => import('./product/ProductDetail'));
export const ProductProcess = lazy(() => import('./product/Process'));
export const ProductLossMoisture = lazy(
  () => import('./product/ProductLossMoisture')
);
export const ProductLossEstimate = lazy(
  () => import('./product/ProductLossEstimate')
);
export const Users = lazy(() => import('./users/Users'));
export const UserCreate = lazy(() => import('./users/UserCreate'));
export const UserUpdate = lazy(() => import('./users/UserUpdate'));
export const ExchangeRates = lazy(() => import('./ExchangeRates'));
export const Roles = lazy(() => import('./roles/Roles'));
export const RoleCreate = lazy(() => import('./roles/RoleCreate'));
export const RoleUpdate = lazy(() => import('./roles/RoleUpdate'));
export const Seasons = lazy(() => import('./seasons/Seasons'));
export const Wallets = lazy(() => import('./wallets/Wallets'));
export const Zones = lazy(() => import('./zones/Zones'));
export const Company = lazy(() => import('./Company'));
export const Customers = lazy(() => import('./Customers'));
export const ExpenseTypes = lazy(() => import('./expense-types/ExpenseTypes'));
export const MoistureProductPrice = lazy(() => import('./product-prices'));
export const ProductPricePendingAndApprove = lazy(
  () => import('./product-prices/PendingAndApprove')
);
export const ProductPriceUnApproveAndReject = lazy(
  () => import('./product-prices/UnApproveAndReject')
);
