import { Decimal } from 'decimal.js';

// export const newDecimal = (value: string | number) => new Decimal(value);
export const sumArray = ({
  arr,
  decimalPlaces,
}: {
  arr: (number | string)[];
  decimalPlaces?: number;
}) => {
  const totalSum = arr.reduce((sum, item) => {
    // Convert item to a Decimal and add to the running sum
    return sum.plus(new Decimal(item));
  }, new Decimal(0)); // Initialize the sum as Decimal(0)

  // Return the sum as a formatted string with the specified number of decimal places
  return totalSum.toFixed(decimalPlaces);
};
export const plus = (a: string | number, b: string | number) =>
  new Decimal(a).plus(b).toNumber();
export const minus = (a: string | number, b: string | number) =>
  new Decimal(a).minus(b);
export const divide = (
  a: string | number,
  b: string | number,
  decimalPlaces?: number
) => {
  const result = new Decimal(a).dividedBy(b);
  if (decimalPlaces) {
    return result.toFixed(decimalPlaces);
  }
  return result.toNumber();
};
