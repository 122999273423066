import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  Tier,
  TiersFilter,
  DefaultQueries,
  CreateTierForm,
  User,
  WalletAccount,
  AddTierToUser,
  AddWalletToTier,
  Zone,
  TierCategory,
  TierCategoryForm,
} from 'models';

type DialogId =
  | 'addTierToUser'
  | 'addWalletToTier'
  | 'addCategoryToTier'
  | null;
export interface InitialState {
  dialogId: DialogId;
  tiersList: {
    tiers: Tier[];
    isLoading: boolean;
    error: string | null;
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
  };
  createTier: {
    isLoading: boolean;
    error: string | null;
    isCreating: boolean;
    zones: Zone[];
  };
  tierUsers: {
    isLoading: boolean;
    error: string | null;
    users: User[];
    tier: Tier | null;
    isCreating: boolean;
  };
  tierWallets: {
    isLoading: boolean;
    error: string | null;
    wallets: WalletAccount[];
    tier: Tier | null;
    isCreating: boolean;
  };
  usersList: {
    isLoading: boolean;
    error: string | null;
    users: User[];
  };
  walletsList: {
    isLoading: boolean;
    error: string | null;
    wallets: WalletAccount[];
  };
  category: {
    isLoading: boolean;
    isCreating: boolean;
    error: string | null;
    data: TierCategory[];
  };
}
const initialState: InitialState = {
  dialogId: null,
  createTier: {
    isLoading: false,
    error: null,
    isCreating: false,
    zones: [],
  },
  tiersList: {
    error: null,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
    tiers: [],
  },
  tierUsers: {
    isLoading: false,
    error: null,
    users: [],
    tier: null,
    isCreating: false,
  },
  tierWallets: {
    isLoading: false,
    error: null,
    wallets: [],
    tier: null,
    isCreating: false,
  },
  usersList: {
    isLoading: false,
    error: null,
    users: [],
  },
  walletsList: {
    isLoading: false,
    error: null,
    wallets: [],
  },
  category: {
    isLoading: false,
    isCreating: false,
    error: null,
    data: [],
  },
};
export enum Types {
  onChangeDialogId = 'super-admin/tiers/onChangeDialogId',
  getTiersRequest = 'super-admin/tiers@getTiersRequest',
  getTiersSuccess = 'super-admin/tiers@getTiersSuccess',
  getTiersFailure = 'super-admin/tiers@getTiersFailure',
  onChangeTiersPage = 'super-admin/tiers@onChangeTiersPage',
  onChangeTiersPageSize = 'super-admin/tiers@onChangeTiersPageSize',
  createTierRequest = 'super-admin/tiers@createTierRequest',
  createTierSuccess = 'super-admin/tiers@createTierSuccess',
  createTierFailure = 'super-admin/tiers@createTierFailure',
  cancelRequestAPI = 'super-admin/tiers@cancelRequestAPI',
  getTierUsersRequest = 'super-admin/tiers@getTierUsersRequest',
  getTierUsersSuccess = 'super-admin/tiers@getTierUsersSuccess',
  getTierUsersFailure = 'super-admin/tiers@getTierUsersFailure',
  stUserToTierUsers = 'super-admin/tiers@stUserToTierUsers',
  addTierToUserRequest = 'super-admin/tiers@addTierToUserRequest',
  addTierToUserSuccess = 'super-admin/tiers@addTierToUserSuccess',
  addTierToUserFailure = 'super-admin/tiers@addTierToUserFailure',
  getTierWalletsRequest = 'super-admin/tiers@getTierWalletsRequest',
  getTierWalletsSuccess = 'super-admin/tiers@getTierWalletsSuccess',
  getTierWalletsFailure = 'super-admin/tiers@getTierWalletsFailure',
  getUsersListRequest = 'super-admin/tiers@getUsersListRequest',
  getUsersListSuccess = 'super-admin/tiers@getUsersListSuccess',
  getUsersListFailure = 'super-admin/tiers@getUsersListFailure',
  getWalletsListRequest = 'super-admin/tiers@getWalletsListRequest',
  getWalletsListSuccess = 'super-admin/tiers@getWalletsListSuccess',
  getWalletsListFailure = 'super-admin/tiers@getWalletsListFailure',
  openDialogAddTierToUser = 'super-admin/tiers@openDialogAddTierToUser',
  openDialogAddWalletToTier = 'super-admin/tiers@openDialogAddWalletToTier',
  setWalletToTierWallets = 'super-admin/tiers@setWalletToTierWallets',
  addWalletToTierRequest = 'super-admin/tiers@addWalletToTierRequest',
  addWalletToTierSuccess = 'super-admin/tiers@addWalletToTierSuccess',
  addWalletToTierFailure = 'super-admin/tiers@addWalletToTierFailure',
  openPageCreateTierRequest = 'super-admin/tiers@openPageCreateTierRequest',
  openPageCreateTierSuccess = 'super-admin/tiers@openPageCreateTierSuccess',
  openPageCreateTierFailure = 'super-admin/tiers@openPageCreateTierFailure',
  listCategoriesRequest = 'super-admin/tiers@ListCategoriesRequest',
  listCategoriesSuccess = 'super-admin/tiers@ListCategoriesSuccess',
  listCategoriesFailure = 'super-admin/tiers@ListCategoriesFailure',
  addCategoryToTierRequest = 'super-admin/tiers@addCategoryToTierRequest',
  addCategoryToTierSuccess = 'super-admin/tiers@addCategoryToTierSuccess',
  addCategoryToTierFailure = 'super-admin/tiers@addCategoryToTierFailure',
}
export type GetTiersSuccess = DefaultQueries & {
  tiers: Tier[];
};
export type GetTierUsersSuccess = {
  users: User[];
  tier: Tier;
};
export type GetTierWalletsSuccess = {
  wallets: WalletAccount[];
  tier: Tier;
};
export type OpenPageCreateTierSuccess = {
  zones: Zone[];
};
export const onChangeDialogId = createAction<DialogId>(Types.onChangeDialogId);
export const getTiersRequest = createAction<TiersFilter>(Types.getTiersRequest);
export const getTiersSuccess = createAction<GetTiersSuccess>(
  Types.getTiersSuccess
);
export const getTiersFailure = createAction<string>(Types.getTiersFailure);
export const onChangeTiersPage = createAction<TiersFilter>(
  Types.onChangeTiersPage
);
export const onChangeTiersPageSize = createAction<TiersFilter>(
  Types.onChangeTiersPageSize
);
export const openPageCreateTierRequest = createAction(
  Types.openPageCreateTierRequest
);
export const openPageCreateTierSuccess =
  createAction<OpenPageCreateTierSuccess>(Types.openPageCreateTierSuccess);
export const openPageCreateTierFailure = createAction<string>(
  Types.openPageCreateTierFailure
);
export const createTierRequest = createAction<CreateTierForm>(
  Types.createTierRequest
);
export const createTierSuccess = createAction(Types.createTierSuccess);
export const createTierFailure = createAction<string>(Types.createTierFailure);
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getTierUsersRequest = createAction<string>(
  Types.getTierUsersRequest
);
export const getTierUsersSuccess = createAction<GetTierUsersSuccess>(
  Types.getTierUsersSuccess
);
export const getTierUsersFailure = createAction<string>(
  Types.getTierUsersFailure
);
export const stUserToTierUsers = createAction<User>(Types.stUserToTierUsers);
export const addTierToUserRequest = createAction<AddTierToUser>(
  Types.addTierToUserRequest
);
export const addTierToUserSuccess = createAction(Types.addTierToUserSuccess);
export const addTierToUserFailure = createAction<string>(
  Types.addTierToUserFailure
);
export const getTierWalletsRequest = createAction<string>(
  Types.getTierWalletsRequest
);
export const getTierWalletsSuccess = createAction<GetTierWalletsSuccess>(
  Types.getTierWalletsSuccess
);
export const getTierWalletsFailure = createAction<string>(
  Types.getTierWalletsFailure
);
export const getUsersListRequest = createAction(Types.getUsersListRequest);
export const getUsersListSuccess = createAction<User[]>(
  Types.getUsersListSuccess
);
export const getUsersListFailure = createAction<string>(
  Types.getUsersListFailure
);
export const getWalletsListRequest = createAction(Types.getWalletsListRequest);
export const getWalletsListSuccess = createAction<WalletAccount[]>(
  Types.getWalletsListSuccess
);
export const getWalletsListFailure = createAction<string>(
  Types.getWalletsListFailure
);
export const openDialogAddTierToUser = createAction(
  Types.openDialogAddTierToUser
);
export const openDialogAddWalletToTier = createAction(
  Types.openDialogAddWalletToTier
);
export const setWalletToTierWallets = createAction<WalletAccount>(
  Types.setWalletToTierWallets
);
export const addWalletToTierRequest = createAction<AddWalletToTier>(
  Types.addWalletToTierRequest
);
export const addWalletToTierSuccess = createAction(
  Types.addWalletToTierSuccess
);
export const addWalletToTierFailure = createAction<string>(
  Types.addWalletToTierFailure
);
export const listCategoriesRequest = createAction<string>(
  Types.listCategoriesRequest
);
export const listCategoriesSuccess = createAction<TierCategory[]>(
  Types.listCategoriesSuccess
);
export const listCategoriesFailure = createAction(Types.listCategoriesFailure);
export const addCategoryToTierRequest = createAction<TierCategoryForm>(
  Types.addCategoryToTierRequest
);
export const addCategoryToTierSuccess = createAction<TierCategory>(
  Types.addCategoryToTierSuccess
);
export const addCategoryToTierFailure = createAction(
  Types.addCategoryToTierFailure
);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(onChangeDialogId, (state, action) => {
      state.dialogId = action.payload;
    })
    .addCase(getTiersRequest, (state) => {
      state.tiersList.isLoading = true;
      state.tiersList.error = null;
    })
    .addCase(getTiersSuccess, (state, action) => {
      return {
        ...state,
        tiersList: { ...state.tiersList, ...action.payload, isLoading: false },
      };
    })
    .addCase(getTiersFailure, (state, action) => {
      state.tiersList.isLoading = false;
      state.tiersList.error = action.payload;
    })
    .addCase(openPageCreateTierRequest, (state) => {
      state.createTier.error = null;
      state.createTier.isLoading = true;
    })
    .addCase(openPageCreateTierSuccess, (state, { payload }) => {
      state.createTier.isLoading = false;
      state.createTier.zones = payload.zones;
    })
    .addCase(openPageCreateTierFailure, (state, action) => {
      state.createTier.error = action.payload;
      state.createTier.isLoading = false;
    })
    .addCase(createTierRequest, (state) => {
      state.createTier.error = null;
      state.createTier.isCreating = true;
    })
    .addCase(createTierSuccess, (state) => {
      state.createTier.isCreating = false;
    })
    .addCase(createTierFailure, (state, action) => {
      state.createTier.error = action.payload;
      state.createTier.isCreating = false;
    })
    .addCase(cancelRequestAPI, (state) => {
      state.tiersList.isLoading = false;
      state.createTier.isCreating = false;
    })
    .addCase(getTierUsersRequest, (state) => {
      state.tierUsers.isLoading = true;
      state.tierUsers.error = null;
    })
    .addCase(getTierUsersSuccess, (state, action) => {
      return {
        ...state,
        tierUsers: { ...state.tierUsers, ...action.payload, isLoading: false },
      };
    })
    .addCase(getTierUsersFailure, (state, action) => {
      state.tierUsers.isLoading = false;
      state.tierUsers.error = action.payload;
    })
    .addCase(stUserToTierUsers, (state, action) => {
      state.tierUsers.users = [action.payload, ...state.tierUsers.users];
      state.dialogId = null;
    })
    .addCase(addTierToUserRequest, (state) => {
      state.tierUsers.error = null;
      state.tierUsers.isCreating = true;
    })
    .addCase(addTierToUserSuccess, (state) => {
      state.tierUsers.isCreating = false;
    })
    .addCase(addTierToUserFailure, (state, action) => {
      state.tierUsers.error = action.payload;
      state.tierUsers.isCreating = false;
    })
    .addCase(getTierWalletsRequest, (state) => {
      state.tierWallets.isLoading = true;
      state.tierWallets.error = null;
    })
    .addCase(getTierWalletsSuccess, (state, action) => {
      return {
        ...state,
        tierWallets: {
          ...state.tierWallets,
          ...action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getTierWalletsFailure, (state, action) => {
      state.tierWallets.isLoading = false;
      state.tierWallets.error = action.payload;
    })
    .addCase(getUsersListRequest, (state) => {
      state.usersList.isLoading = true;
      state.usersList.error = null;
    })
    .addCase(getUsersListSuccess, (state, action) => {
      return {
        ...state,
        usersList: {
          ...state.usersList,
          users: action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getUsersListFailure, (state, action) => {
      state.usersList.isLoading = false;
      state.usersList.error = action.payload;
    })
    .addCase(getWalletsListRequest, (state) => {
      state.walletsList.isLoading = true;
      state.walletsList.error = null;
    })
    .addCase(getWalletsListSuccess, (state, action) => {
      return {
        ...state,
        walletsList: {
          ...state.walletsList,
          wallets: action.payload,
          isLoading: false,
        },
      };
    })
    .addCase(getWalletsListFailure, (state, action) => {
      state.walletsList.isLoading = false;
      state.walletsList.error = action.payload;
    })
    .addCase(openDialogAddTierToUser, (state) => {
      state.dialogId = 'addTierToUser';
    })
    .addCase(openDialogAddWalletToTier, (state) => {
      state.dialogId = 'addWalletToTier';
    })
    .addCase(setWalletToTierWallets, (state, action) => {
      state.tierWallets.wallets = [
        action.payload,
        ...state.tierWallets.wallets,
      ];
      state.dialogId = null;
    })
    .addCase(addWalletToTierRequest, (state) => {
      state.tierWallets.error = null;
      state.tierWallets.isCreating = true;
    })
    .addCase(addWalletToTierSuccess, (state) => {
      state.tierWallets.isCreating = false;
    })
    .addCase(addWalletToTierFailure, (state, action) => {
      state.tierWallets.error = action.payload;
      state.tierWallets.isCreating = false;
    });
  builder
    .addCase(listCategoriesRequest, (state) => {
      state.category.isLoading = true;
    })
    .addCase(listCategoriesSuccess, (state, { payload }) => {
      state.category.isLoading = false;
      state.category.data = payload;
    })
    .addCase(listCategoriesFailure, (state, action) => {
      state.category.isLoading = false;
    })
    .addCase(addCategoryToTierRequest, (state) => {
      state.category.isCreating = true;
    })
    .addCase(addCategoryToTierSuccess, (state, { payload }) => {
      state.category.isCreating = false;
      state.dialogId = null;
      state.category.data = [...state.category.data, payload];
    })
    .addCase(addCategoryToTierFailure, (state, action) => {
      state.category.isCreating = false;
    });
});

export default reducer;
