import moment from 'moment';
import type { Stock } from 'models/inventory';
import type {} from 'models/inventory';
import type { AxiosResponse } from 'axios';

export const getStockStatus = (stock?: Stock | null) => {
  if (!stock) return '';
  const lotIdLength = stock.lotId.length;
  if (stock.status === 'PENDING' && lotIdLength === 0) return 'ລໍຖ້າຕັດລ໋ອດ';
  if (stock.status === 'TRANSFORMING') return 'ກຳລັງແປຮູບ';
  if (stock.status === 'COMPLETE' && lotIdLength === 0)
    return 'ແປຮູບສຳເລັດແລ້ວ, ລໍຖ້າຕັດລ໋ອດ';
  return 'ສຳເລັດແລ້ວ';
};
export const getStockStatusIsProcess = (stock?: Stock | null) => {
  if (!stock) return false;
  const lotIdLength = stock.lotId.length;
  if (stock.status === 'PENDING' && lotIdLength === 0) return false;
  if (stock.status === 'TRANSFORMING') return true;
  if (stock.status === 'COMPLETE' && lotIdLength === 0) return true;
  if (stock.status === 'COMPLETE' && Boolean(stock.toProduct)) return true;
  return false;
};
export const dateStringFormat = ({
  value,
  format = 'DD/MM/YYYY',
}: {
  value?: string | null;
  format?: 'DD/MM/YYYY' | 'DD/MM/YYYY HH:mm:ss' | 'DD/MM/YYYY HH:mm';
}) => {
  if (!value) return '';
  return moment(value).format(format);
};
const removeSemicolon = (value: string | number) => {
  if (typeof value === 'number') return value;
  return Number.parseInt(value.replace(',', ''));
};
export const numberFormat = (value?: number | string | null) => {
  if (!value) return '';
  if (
    value.toString() === 'NaN' ||
    value === '' ||
    value === 0 ||
    value === '0'
  )
    return '0';
  return new Intl.NumberFormat().format(removeSemicolon(value)).toString();
};
export const currencyFormatString = (amount?: Money) => {
  if (!amount) return '';
  return `${numberFormat(amount.amount)} ${amount.currency}`;
};
export const weightFormat = (value?: string | number | null) => {
  if (!value) return '0 Kg';
  return `${numberFormat(value)} Kg`;
};
type BatchGetDataProps<
  R extends FieldValuesObject = FieldValuesObject,
  K extends string = string
> = {
  keyResponse: K;
  query?: string;
  httpRequest: (queries: string) => Promise<
    AxiosResponse<
      {
        [key in K]: R;
      } & {
        nextPageToken: string;
      }
    >
  >;
  maxDataLength?: number;
  pageSize?: number;
};
export const batchGetData = async <
  R extends FieldValuesObject,
  K extends string = string
>({
  keyResponse,
  httpRequest,
  pageSize = 250,
  maxDataLength = 2000,
}: BatchGetDataProps<R, K>): Promise<R[]> => {
  let values: R[] = [];
  let nextPageToken = '';
  let dataLength = 0;
  let isError: null | unknown = null;
  do {
    try {
      let queries = `?pageSize=${pageSize}`;
      if (nextPageToken.length !== 0) {
        queries = queries + `&pageToken=${nextPageToken}`;
        nextPageToken = '';
      }
      if (maxDataLength === dataLength) {
        break;
      }
      const { data } = await httpRequest(queries);
      dataLength = dataLength + pageSize;
      const pageToken = data['nextPageToken'];
      if (!data[keyResponse]) {
        throw new Error('Key data response not found.');
      }
      values = values.concat(data[keyResponse]);
      if (pageToken && pageToken.length !== 0) {
        nextPageToken = pageToken;
      }
    } catch (error) {
      isError = error;
      break;
    }
  } while (nextPageToken.length !== 0);
  if (isError) {
    throw isError;
  }
  return values;
};
