import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  takeLeading,
  take,
  delay,
} from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { push } from 'redux-first-history';
import { addAlert } from 'store/notify';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { RootState } from 'store';
import * as companyActions from 'store/super-admin/company';
import {
  GetOrdersResponse,
  OrdersQueries,
  CreateOrder,
  GetOrderResponse,
  GetCustomersResponse,
  UpdateOrder,
  DefaultQueries,
  Tier,
  SaleInvoice,
  SaleTransaction,
} from 'models';
import {
  findErrorToData,
  mappingParamsToPayload,
  setPageInfo,
  getAllDataWithCursorNextPage,
} from 'utils';

let cancels: Canceler[] = [];
function* getOrders({ payload }: ReturnType<typeof actions.getOrdersRequest>) {
  const {
    saleOrders: { ordersList },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
  let params: any = {
    ...payloadParams,
    pageSize: pageSize || ordersList.pageSize,
  };
  if (startedAt && !endedAt) {
    params.createdAt = startedAt;
  }
  if (startedAt && endedAt) {
    params.startedAt = startedAt;
    params.endedAt = endedAt;
  }
  try {
    const { data }: GetOrdersResponse = yield call(
      API.get,
      `/v1/sale/purchaseOrders`,
      {
        cancelToken: cancelToken.token,
        params: params,
      }
    );
    const pageInfo = setPageInfo(
      {
        page: ordersList.page,
        pageSize: ordersList.pageSize,
        pageTokens: ordersList.pageTokens,
      },
      {
        page: page,
        pageSize: pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    const dataPayload: actions.GetOrdersSuccess = {
      ...pageInfo,
      orders: data.purchaseOrders,
    };
    yield put(actions.getOrdersSuccess(dataPayload));
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getOrdersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeOrdersPage({
  payload,
}: ReturnType<typeof actions.onChangeOrdersPage>) {
  const {
    ordersList: { page, pageTokens },
  }: actions.InitialState = yield select(
    (state: RootState) => state.saleOrders
  );
  const { nextOrPrev, ...payloadParams } = payload;
  let params: OrdersQueries & DefaultQueries = {
    ...payloadParams,
  };
  const prevPage = nextOrPrev && nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  params.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    params.pageToken = pageToken;
  }
  yield put(actions.getOrdersRequest(params));
}
function* onChangeOrdersPageSize({
  payload,
}: ReturnType<typeof actions.onChangeOrdersPageSize>) {
  const {
    ordersList: { pageSize },
  }: actions.InitialState = yield select(
    (state: RootState) => state.saleOrders
  );
  if (payload.pageSize !== pageSize) {
    yield put(actions.getOrdersRequest({ ...payload }));
  }
}
function* createOrder({
  payload,
}: ReturnType<typeof actions.createOrderRequest>) {
  const {
    auth,
    saleOrders: {
      customersList,
      productsList: { products },
    },
    router: { location },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let url = `v1/sale/purchaseOrders`;
  const customer = customersList.customers.find(
    (c) => c.value === payload.customerId
  );
  const product = products.find((p) => p.value === payload.productTitle);
  let order: CreateOrder = {
    customer: {
      displayName: customer?.label || '',
      id: payload.customerId,
    },
    paymentMethod: payload.paymentMethod,
    price: {
      amount: payload.price.amount,
      currency: payload.currency,
    },
    product: {
      name: payload.productTitle,
      title: product?.label || '',
    },
    remark: payload.remark || '',
    description: payload.description || '',
    shippedAt: payload.shippedAt,
    tier: auth.accept.tier?.name || '',
    totalAmount: {
      amount: payload.totalAmount.amount,
      currency: payload.currency,
    },
    type: payload.type,
    volumeKg: payload.volumeKg,
    location: payload.location || '',
    shippedEndAt: payload.shippedEndAt,
  };
  if (payload.type === 'TIER_TO_TIER') {
    order.toTier = payload.toTier;
  }
  try {
    yield call(API.post, url, order, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.createOrderSuccess());
    yield put(
      addAlert({
        message: 'ເພີ່ມໃບສັ່ງຊື້ສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );

    cancels = [];
    yield put(
      push({
        pathname: `/tiers/${auth.accept.tier?.name}/sale/orders`,
        search: location?.search || '',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createOrderFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateOrder({
  payload,
}: ReturnType<typeof actions.updateOrderRequest>) {
  const {
    auth,
    saleOrders: { orderDetail },
    router: { location },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let order: UpdateOrder = {
    paymentMethod: payload.paymentMethod,
    price: {
      amount: payload.price.amount,
      currency: payload.currency,
    },
    remark: payload.remark || '',
    description: payload.description || '',
    shippedAt: payload.shippedAt,
    totalAmount: {
      amount: payload.totalAmount.amount,
      currency: payload.currency,
    },
    type: payload.type,
    volumeKg: payload.volumeKg,
    id: orderDetail.order?.id || '',
    location: payload.location,
    shippedEndAt: payload.shippedEndAt,
  };
  if (payload.type === 'TIER_TO_TIER') {
    order.toTier = orderDetail.order?.toTierObj.name || '';
  }
  try {
    yield call(API.post, `/v1/sale/purchaseOrders-update`, order, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.updateOrderSuccess());
    yield put(
      addAlert({
        message: 'ແກ້ໄຂໃບສັ່ງຊື້ສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );

    cancels = [];
    yield put(
      push({
        pathname: `/tiers/${auth.accept.tier?.name}/sale/orders`,
        search: location?.search || '',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateOrderFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getOrder({ payload }: ReturnType<typeof actions.getOrderRequest>) {
  const {
    auth,
    saleOrders: {
      orderDetail: { order },
    },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let url = `v1/sale/purchaseOrders/${payload}?tier=${
    auth.accept.tier?.name || ''
  }`;
  try {
    const { data }: GetOrderResponse = yield call(API.get, url, {
      cancelToken: cancelToken.token,
    });
    const invoices: SaleInvoice[] = yield getAllDataWithCursorNextPage<
      Tier[],
      'invoices'
    >({
      httpRequest: (queries) =>
        API.get(
          `/v1/sale/invoices${queries}&tier=${
            auth.accept.tier?.name || ''
          }&po=${order?.id || ''}&orderBy=ASC`
        ),
      keyResponse: 'invoices',
    });
    yield put(
      actions.getOrderSuccess({
        order: data.purchaseOrder,
        invoices: invoices,
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getOrderFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getCustomers() {
  const { auth }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let url = `v1/tiers/${auth.accept.tier?.name || ''}/customers`;
  try {
    const { data }: GetCustomersResponse = yield call(API.get, url, {
      cancelToken: cancelToken.token,
      params: {
        pageSize: 250,
      },
    });
    const options = data.customers.map((customer) => ({
      label: customer.displayName,
      value: customer.id,
    }));
    yield put(actions.getCustomersSuccess(options));
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getCustomersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getTiers() {
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  try {
    const tiers: Tier[] = yield getAllDataWithCursorNextPage<Tier[], 'tiers'>({
      httpRequest: (queries) =>
        API.get(`/v1/admin/tier/tiers${queries}`, {
          cancelToken: cancelToken.token,
        }),
      keyResponse: 'tiers',
    });
    yield put(actions.getTiersSuccess(tiers));
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getTiersFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateStatus() {
  const {
    saleOrders: {
      orderDetail: { order },
    },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);

  try {
    yield call(
      API.post,
      `/v1/sale/purchaseOrders-confirm`,
      { id: order?.id || '' },
      {
        cancelToken: cancelToken.token,
      }
    );
    yield put(actions.updateStatusSuccess());
    cancels = [];

    yield put(
      addAlert({
        message: 'ແກ້ໄຂສະຖານະສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateStatusFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* printOrder() {
  const {
    saleOrders: {
      printOrder: { order },
    },
    superAdmin: { company },
  }: RootState = yield select((state) => state);
  if (!order) {
    yield put(actions.printOrderFailure());
    return;
  }
  if (company.company.data.id.length === 0) {
    yield put(companyActions.getCompanyRequest());
    const companyRes:
      | ReturnType<typeof companyActions.getCompanyFailure>
      | ReturnType<typeof companyActions.getCompanySuccess> = yield take([
      companyActions.Types.getCompanyFailure,
      companyActions.Types.getCompanySuccess,
    ]);
    if (companyRes.type === companyActions.Types.getCompanyFailure) {
      yield put(actions.printOrderFailure());
      return;
    }
  }
  yield delay(600);
  yield put(actions.printOrderReady());
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* exportCsv({ payload }: ReturnType<typeof actions.exportCsvRequest>) {
  const {
    auth: { accept },
  }: RootState = yield select((state) => state);
  try {
    const queries: string = yield mappingParamsToPayload({
      values: {
        ...payload,
        tier: accept.tier?.name || '',
      },
      keysParams: ['tier', 'status', 'customer', 'startedAt', 'endedAt'],
    });
    const { data } = yield call(
      API.post,
      `/v1/sale/purchaseOrders-exportCsv`,
      {},
      { params: queries }
    );
    const blob = new Blob(['\uFEFF' + data], {
      type: 'text/csv; charset=utf-8',
    });
    yield saveAs(blob, `ລາຍການໃບສັ່ງຊື້${moment().format('DD/MM/YYYY')}.csv`);
    yield put(actions.exportCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* printInvoice() {
  const {
    saleOrders: {
      printInvoice: { invoice },
    },
    superAdmin: {
      company: { company },
    },
    auth: {
      accept: { tier },
    },
  }: RootState = yield select((state) => state);
  if (!invoice) {
    yield put(actions.printInvoiceFailure());
    return;
  }
  try {
    const transactions: SaleTransaction[] = yield getAllDataWithCursorNextPage<
      SaleTransaction,
      'transactions'
    >({
      httpRequest: (queries) =>
        API.get(
          `/v1/sale/transactions${queries}&invoiceId=${invoice.id}&tier=${
            tier?.name || ''
          }`
        ),
      keyResponse: 'transactions',
    });
    if (company.data.id.length === 0) {
      yield put(companyActions.getCompanyRequest());
      const companyRes:
        | ReturnType<typeof companyActions.getCompanyFailure>
        | ReturnType<typeof companyActions.getCompanySuccess> = yield take([
        companyActions.Types.getCompanyFailure,
        companyActions.Types.getCompanySuccess,
      ]);
      if (companyRes.type === companyActions.Types.getCompanyFailure) {
        yield put(actions.printInvoiceFailure());
        return;
      }
    }
    const sortTransactions = transactions.sort(
      (a, b) => Number.parseFloat(a.number) - Number.parseFloat(b.number)
    );
    yield put(
      actions.setDataToPrint({
        transactions: sortTransactions,
      })
    );
    yield delay(100);
    yield put(actions.printInvoiceReady());
  } catch (error) {
    yield put(actions.printInvoiceFailure());
  }
}
function* watchGetOrdersRequest() {
  yield takeLatest(actions.Types.getOrdersRequest, getOrders);
}
function* watchOnChangeOrdersPage() {
  yield takeLatest(actions.Types.onChangeOrdersPage, onChangeOrdersPage);
}
function* watchOnChangePageSize() {
  yield takeLatest(
    actions.Types.onChangeOrdersPageSize,
    onChangeOrdersPageSize
  );
}
function* watchCreateOrder() {
  yield takeLeading(actions.Types.createOrderRequest, createOrder);
}
function* watchGetOrder() {
  yield takeLatest(actions.Types.getOrderRequest, getOrder);
}
function* watchGetCustomers() {
  yield takeLatest(actions.Types.openCreateOrderPage, getCustomers);
}
function* watchGetTiers() {
  yield takeLatest(actions.Types.openCreateOrderPage, getTiers);
}
function* watchUpdateOrder() {
  yield takeLatest(actions.Types.updateOrderRequest, updateOrder);
}
function* watchPrintOrder() {
  yield takeLeading(actions.Types.printOrderRequest, printOrder);
}
function* watchUpdateStatus() {
  yield takeLatest(actions.Types.updateStatusRequest, updateStatus);
}
function* watchExportCsv() {
  yield takeLatest(actions.Types.exportCsvRequest, exportCsv);
}
function* watchPrintInvoice() {
  yield takeLeading(actions.Types.printInvoiceRequest, printInvoice);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetOrdersRequest),
    fork(watchOnChangeOrdersPage),
    fork(watchOnChangePageSize),
    fork(watchCreateOrder),
    fork(watchGetOrder),
    fork(watchGetCustomers),
    fork(watchUpdateOrder),
    fork(watchPrintOrder),
    fork(watchUpdateStatus),
    fork(watchExportCsv),
    fork(watchGetTiers),
    fork(watchPrintInvoice),
  ]);
}
export default saga;
