import { createReducer, createAction } from '@reduxjs/toolkit';
import {
  DefaultQueries,
  StocksTransformFilter,
  Stock,
  StockQualityCheck,
  StockQualityCheckForm,
  StockProcess,
  QcProcess,
  CloseStepProcess,
} from 'models';

type DialogID =
  | null
  | 'confirmTransform'
  | 'qualityCheckForm'
  | 'close-process'
  | 'close-step-process';

export interface InitialState {
  list: {
    stocks: Stock[];
    page: number;
    pageSize: number;
    pageTokens: string[];
    isLoading: boolean;
    error: string | null;
  };
  dialogID: DialogID;
  get: {
    isLoading: boolean;
    error: string | null;
    isPending: boolean;
    stock: Stock | null;
  };
  process: Omit<StockProcess, 'product'> & {
    isLoading: boolean;
    isPending: boolean;
    product: {
      name: string;
      title: string;
    } | null;
  };
}
const initialState: InitialState = {
  list: {
    stocks: [],
    page: 1,
    pageSize: 25,
    pageTokens: [],
    isLoading: false,
    error: null,
  },
  dialogID: null,
  get: {
    isLoading: false,
    error: null,
    isPending: false,
    stock: null,
  },
  process: {
    isLoading: false,
    isPending: false,
    product: null,
    qc: {},
    step: {},
    currentStep: 0,
  },
};
export enum Types {
  cancelRequestAPI = 'tier/inventory/stocks-transform@cancelRequestAPI',
  onChangeDialogID = 'tier/inventory/stocks-transform@onChangeDialogID',

  getStocksRequest = 'tier/inventory/stocks-transform@getStocksRequest',
  getStocksSuccess = 'tier/inventory/stocks-transform@getStocksSuccess',
  getStocksFailure = 'tier/inventory/stocks-transform@getStocksFailure',
  createQualityCheckRequest = 'tier/inventory/stocks-transform@createQualityCheckRequest',
  createQualityCheckSuccess = 'tier/inventory/stocks-transform@createQualityCheckSuccess',
  createQualityCheckFailure = 'tier/inventory/stocks-transform@createQualityCheckFailure',
  getStockRequest = 'tier/inventory/stocks-transform@getStockRequest',
  getStockSuccess = 'tier/inventory/stocks-transform@getStockSuccess',
  getStockFailure = 'tier/inventory/stocks-transform@getStockFailure',
  getQualityChecksSuccess = 'tier/inventory/stocks-transform@getQualityChecksSuccess',
  getQualityChecksFailure = 'tier/inventory/stocks-transform@getQualityChecksFailure',
  createCompleteTransformRequest = 'tier/inventory/stocks-transform@createCompleteTransformRequest',
  createCompleteTransformSuccess = 'tier/inventory/stocks-transform@createCompleteTransformSuccess',
  createCompleteTransformFailure = 'tier/inventory/stocks-transform@createCompleteTransformFailure',
  listProcessStepRequest = 'tier/inventory/listProcessStepRequest',
  listProcessStepSuccess = 'tier/inventory/listProcessStepSuccess',
  listProcessStepFailure = 'tier/inventory/listProcessStepFailure',
  setQc = 'tier/inventory/setQc',
  qcError = 'tier/inventory/qcError',
  closeStepProcessRequest = 'tier/inventory/stocks-transform@closeStepProcessRequest',
  closeStepProcessSuccess = 'tier/inventory/stocks-transform@closeStepProcessSuccess',
  closeStepProcessFailure = 'tier/inventory/stocks-transform@closeStepProcessFailure',
}

export type GetStocksSuccess = DefaultQueries & {
  stocks: Stock[];
};
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const onChangeDialogID = createAction<DialogID>(Types.onChangeDialogID);
export const getStocksRequest = createAction<StocksTransformFilter>(
  Types.getStocksRequest
);
export const getStocksSuccess = createAction<GetStocksSuccess>(
  Types.getStocksSuccess
);
export const getStocksFailure = createAction<string>(Types.getStocksFailure);
export const createQualityCheckRequest = createAction<StockQualityCheckForm>(
  Types.createQualityCheckRequest
);
export const createQualityCheckSuccess = createAction<StockQualityCheckForm>(
  Types.createQualityCheckSuccess
);
export const createQualityCheckFailure = createAction<string>(
  Types.createQualityCheckFailure
);
export const getStockRequest = createAction<string>(Types.getStockRequest);
export const getStockSuccess = createAction<Stock>(Types.getStockSuccess);
export const getStockFailure = createAction<string>(Types.getStockFailure);
export const closeStepProcessRequest = createAction<CloseStepProcess>(
  Types.closeStepProcessRequest
);
export const closeStepProcessSuccess = createAction(
  Types.closeStepProcessSuccess
);
export const closeStepProcessFailure = createAction(
  Types.closeStepProcessFailure
);

export const getQualityChecksSuccess = createAction<StockQualityCheck[]>(
  Types.getQualityChecksSuccess
);
export const getQualityChecksFailure = createAction<string>(
  Types.getQualityChecksFailure
);
export const createCompleteTransformRequest = createAction<string>(
  Types.createCompleteTransformRequest
);
export const createCompleteTransformSuccess = createAction(
  Types.createCompleteTransformSuccess
);
export const createCompleteTransformFailure = createAction<string>(
  Types.createCompleteTransformFailure
);
export const listProcessStepRequest = createAction<Stock>(
  Types.listProcessStepRequest
);
export const listProcessStepSuccess = createAction<StockProcess>(
  Types.listProcessStepSuccess
);
export const listProcessStepFailure = createAction(
  Types.listProcessStepFailure
);
export const setQc = createAction<QcProcess>(Types.setQc);
export const qcError = createAction<undefined>(Types.qcError);
const reducer = createReducer(initialState, (builder) => {
  builder.addCase(cancelRequestAPI, (state) => {
    state.list.isLoading = false;
    state.dialogID = null;
    state.get.isLoading = false;
  });
  builder.addCase(onChangeDialogID, (state, action) => {
    state.dialogID = action.payload;
  });
  builder.addCase(getStocksRequest, (state) => {
    state.list.isLoading = true;
    state.list.error = null;
  });
  builder.addCase(getStocksSuccess, (state, action) => {
    return {
      ...state,
      list: {
        ...state.list,
        ...action.payload,
        isLoading: false,
      },
    };
  });
  builder.addCase(getStocksFailure, (state, action) => {
    state.list.isLoading = false;
    state.list.error = action.payload;
  });
  builder.addCase(createQualityCheckRequest, (state) => {
    state.process.isPending = true;
  });
  builder.addCase(createQualityCheckSuccess, (state, { payload }) => {
    state.process.isPending = false;
    state.dialogID = null;
  });
  builder.addCase(createQualityCheckFailure, (state, action) => {
    state.process.isPending = false;
  });
  builder.addCase(getStockRequest, (state) => {
    state.get.isLoading = true;
    state.get.error = null;
  });
  builder.addCase(getStockSuccess, (state, action) => {
    state.get.stock = action.payload;
    state.get.isLoading = false;
  });
  builder.addCase(getStockFailure, (state, action) => {
    state.get.isLoading = false;
    state.get.error = action.payload;
  });
  builder.addCase(getQualityChecksFailure, (state, action) => {
    state.get.isLoading = false;
    state.get.error = action.payload;
  });
  builder.addCase(createCompleteTransformRequest, (state) => {
    state.get.isPending = true;
  });
  builder.addCase(createCompleteTransformSuccess, (state) => {
    state.get.isPending = false;
    state.dialogID = null;
  });
  builder.addCase(createCompleteTransformFailure, (state, action) => {
    state.get.isPending = false;
  });
  builder
    .addCase(listProcessStepRequest, (state) => {
      state.process.isLoading = true;
      state.process.product = null;
      state.process.currentStep = 0;
      state.process.qc = {};
      state.process.step = {};
    })
    .addCase(listProcessStepSuccess, (state, { payload }) => {
      state.process = {
        isLoading: false,
        isPending: false,
        ...payload,
      };
      // state.dialogID = null;
    })
    .addCase(listProcessStepFailure, (state) => {
      state.process.isLoading = false;
      state.process.isPending = false;
    });
  builder.addCase(closeStepProcessSuccess, (state) => {
    state.dialogID = null;
  });
});

export default reducer;
