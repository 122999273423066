import { createReducer, createAction } from '@reduxjs/toolkit';
import type {
  Tier,
  Customer,
  Season,
  Zone,
  Product,
  DefaultQueries,
} from 'models';
export interface InitialState {
  customers: {
    isLoading: boolean;
    error: string | null;
    entities: Customer[];
    nextPageToken: string;
  };
  products: {
    isLoading: boolean;
    error: string | null;
    entities: Product[];
    nextPageToken: string;
  };
  tiers: {
    isLoading: boolean;
    error: string | null;
    entities: Tier[];
    nextPageToken: string;
  };
  zones: {
    isLoading: boolean;
    error: string | null;
    entities: Zone[];
    nextPageToken: string;
  };
  seasons: {
    isLoading: boolean;
    error: string | null;
    entities: Season[];
    nextPageToken: string;
  };
}
const initialState: InitialState = {
  customers: {
    entities: [],
    isLoading: false,
    nextPageToken: '',
    error: null,
  },
  products: {
    entities: [],
    isLoading: false,
    nextPageToken: '',
    error: null,
  },
  seasons: {
    entities: [],
    isLoading: false,
    nextPageToken: '',
    error: null,
  },
  zones: {
    entities: [],
    isLoading: false,
    nextPageToken: '',
    error: null,
  },
  tiers: {
    entities: [],
    isLoading: false,
    nextPageToken: '',
    error: null,
  },
};
export enum Types {
  getCustomersRequest = 'global-shares-data@getCustomersRequest',
  getCustomersSuccess = 'global-shares-data@getCustomersSuccess',
  getCustomersFailure = 'global-shares-data@getCustomersFailure',
  nextPageCustomers = 'global-shares-data@nextPageCustomers',
  getProductsRequest = 'global-shares-data@getProductsRequest',
  getProductsSuccess = 'global-shares-data@getProductsSuccess',
  getProductsFailure = 'global-shares-data@getProductsFailure',
  nextPageProducts = 'global-shares-data@nextPageProducts',
  getSeasonsRequest = 'global-shares-data@getSeasonsRequest',
  getSeasonsSuccess = 'global-shares-data@getSeasonsSuccess',
  getSeasonsFailure = 'global-shares-data@getSeasonsFailure',
  nextPageSeasons = 'global-shares-data@nextPageSeasons',
  getZonesRequest = 'global-shares-data@getZonesRequest',
  getZonesSuccess = 'global-shares-data@getZonesSuccess',
  getZonesFailure = 'global-shares-data@getZonesFailure',
  nextPageZones = 'global-shares-data@nextPageZones',
  getTiersRequest = 'global-shares-data@getTiersRequest',
  getTiersSuccess = 'global-shares-data@getTiersSuccess',
  getTiersFailure = 'global-shares-data@getTiersFailure',
  nextPageTiers = 'global-shares-data@nextPageTiers',
}
export const getCustomersRequest = createAction<DefaultQueries>(
  Types.getCustomersRequest
);
export const getCustomersSuccess = createAction<{
  entities: Customer[];
  nextPageToken: string;
}>(Types.getCustomersSuccess);
export const getCustomersFailure = createAction<string>(
  Types.getCustomersFailure
);
export const nextPageCustomers = createAction(Types.nextPageCustomers);
export const getProductsRequest = createAction<DefaultQueries>(
  Types.getProductsRequest
);
export const getProductsSuccess = createAction<{
  entities: Product[];
  nextPageToken: string;
}>(Types.getProductsSuccess);
export const getProductsFailure = createAction<string>(
  Types.getProductsFailure
);
export const nextPageProducts = createAction(Types.nextPageProducts);
export const getSeasonsRequest = createAction<DefaultQueries>(
  Types.getSeasonsRequest
);
export const getSeasonsSuccess = createAction<{
  entities: Season[];
  nextPageToken: string;
}>(Types.getSeasonsSuccess);
export const getSeasonsFailure = createAction<string>(Types.getSeasonsFailure);
export const nextPageSeasons = createAction(Types.nextPageSeasons);
export const getZonesRequest = createAction<DefaultQueries>(
  Types.getZonesRequest
);
export const getZonesSuccess = createAction<{
  entities: Zone[];
  nextPageToken: string;
}>(Types.getZonesSuccess);
export const getZonesFailure = createAction<string>(Types.getZonesFailure);
export const nextPageZones = createAction(Types.nextPageZones);
export const getTiersRequest = createAction<DefaultQueries>(
  Types.getTiersRequest
);
export const getTiersSuccess = createAction<{
  entities: Tier[];
  nextPageToken: string;
}>(Types.getTiersSuccess);
export const getTiersFailure = createAction(Types.getTiersFailure);
export const nextPageTiers = createAction(Types.nextPageTiers);

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getCustomersRequest, (state) => {
      state.customers.isLoading = true;
    })
    .addCase(getCustomersSuccess, (state, { payload }) => {
      state.customers.isLoading = false;
      state.customers.entities = payload.entities;
      state.customers.nextPageToken = payload.nextPageToken;
    })
    .addCase(getCustomersFailure, (state) => {
      state.customers.isLoading = false;
    });
  builder
    .addCase(getProductsRequest, (state) => {
      state.products.isLoading = true;
    })
    .addCase(getProductsSuccess, (state, { payload }) => {
      state.products.isLoading = false;
      state.products.entities = payload.entities;
      state.products.nextPageToken = payload.nextPageToken;
    })
    .addCase(getProductsFailure, (state) => {
      state.products.isLoading = false;
    });
  builder
    .addCase(getSeasonsRequest, (state) => {
      state.seasons.isLoading = true;
    })
    .addCase(getSeasonsSuccess, (state, { payload }) => {
      state.seasons.isLoading = false;
      state.seasons.entities = payload.entities;
      state.seasons.nextPageToken = payload.nextPageToken;
    })
    .addCase(getSeasonsFailure, (state) => {
      state.seasons.isLoading = false;
    });
  builder
    .addCase(getZonesRequest, (state) => {
      state.zones.isLoading = true;
    })
    .addCase(getZonesSuccess, (state, { payload }) => {
      state.zones.isLoading = false;
      state.zones.entities = payload.entities;
      state.zones.nextPageToken = payload.nextPageToken;
    })
    .addCase(getZonesFailure, (state) => {
      state.zones.isLoading = false;
    });
  builder
    .addCase(getTiersRequest, (state) => {
      state.tiers.isLoading = true;
    })
    .addCase(getTiersSuccess, (state, { payload }) => {
      state.tiers.isLoading = false;
      state.tiers.entities = payload.entities;
      state.tiers.nextPageToken = payload.nextPageToken;
    })
    .addCase(getTiersFailure, (state) => {
      state.tiers.isLoading = false;
    });
});

export default reducer;
