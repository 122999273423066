import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API from 'services/defaultInstance';
// import  {Types,} from './index'
import * as actions from './index';

import { findErrorToData } from 'utils';
import { AxiosResponse } from 'axios';
// import { getAllDataWithCursorNextPage } from 'utils/index';
import type { Summary, SummaryEntry } from 'models/purchase.report.v2';
import { plus } from 'utils/format-number';
function* listSummaries({
  payload,
}: ReturnType<typeof actions.listSummariesRequest>) {
  const { endedAt, startedAt, keyName, ...props } = payload;
  const params = {
    ...props,
  };
  try {
    const { data } = (yield call(API.get, `/v1/dashboard/summary-purchase`, {
      params,
    })) as AxiosResponse<{ sale: Summary[] }>;
    const { timelines, data: purchases } = data.sale.reduce(
      (values, item) => {
        const key = `${item.tier.name}-${item.product.name}`;
        const result = item[keyName];
        const getDataValue = values.data[key] ?? {
          ...item,
          count: 0,
          price: {
            amount: '0',
            currency: item.price.currency,
          },
          amount: '0',
          weight: 0,
          summary: {
            [item.timeline.value]: {
              value: 0,
              label: keyName === 'amount' ? 'LAK' : 'KG',
            },
          },
          result: {
            price: 0,
            amount: 0,
            weight: 0,
            count: 0,
            tierPurchasePrice: 0,
          },
        };
        const getTimeLineValue = getDataValue.summary[item.timeline.value] ?? {
          value: 0,
          label: keyName === 'amount' ? 'LAK' : 'KG',
        };
        return {
          data: {
            ...values.data,
            [key]: {
              ...getDataValue,
              summary: {
                ...getDataValue.summary,
                [item.timeline.value]: {
                  value: plus(getTimeLineValue.value, result),
                  label: getTimeLineValue.label,
                },
              },
              result: {
                price: plus(getDataValue.result.price, item.price.amount),
                amount: plus(getDataValue.result.amount, item.amount),
                weight: plus(getDataValue.result.weight, item.weight),
                count: plus(getDataValue.result.count, item.count),
                tierPurchasePrice: plus(
                  getDataValue.result.tierPurchasePrice,
                  item.tierPurchasePrice.amount
                ),
              },
            },
          },
          timelines: {
            ...values.timelines,
            [item.timeline.value]: item.timeline.value,
          },
        };
      },
      { timelines: {}, data: {} } as {
        timelines: {
          [key: number]: number;
        };
        data: {
          [key: string]: SummaryEntry;
        };
      }
    );
    yield put(
      actions.listSummariesSuccess({
        entries: Object.values(purchases),
        timelines: Object.values(timelines).sort((a, b) => a - b),
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listSummariesFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchListSummaries() {
  yield takeLatest(actions.Types.listSummariesRequest, listSummaries);
}
function* saga() {
  yield all([fork(watchListSummaries)]);
}
export default saga;
