import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  Purchase,
  PurchasesQueries,
  AuditField,
  AuditType,
  AuditStatus,
} from 'models/purchase.v2';
import type {
  SaleTransaction,
  SaleTransactionsQueries,
  AuditSaleField,
} from 'models/sale';
export interface InitialState {
  purchase: {
    [key: string]: {
      data: Purchase[];
      isLoading: boolean;
    };
  };
  isCreating: boolean;
  dialogId: null | 'audits' | 'head-audit';
  isAfterSubmit: boolean;
  sale: {
    [key: string]: {
      data: SaleTransaction[];
      isLoading: boolean;
    };
  };
}
const initialState: InitialState = {
  purchase: {},
  dialogId: null,
  isCreating: false,
  isAfterSubmit: false,
  sale: {},
};
export enum Types {
  listPurchaseRequest = 'audit/listPurchaseRequest',
  listPurchaseSuccess = 'audit/listPurchaseSuccess',
  listPurchaseFailure = 'audit/listPurchaseFailure',
  onChangeDialogId = 'audit/changeDialogId',
  createPurchaseRequest = 'audit/createPurchaseRequest',
  createPurchaseSuccess = 'audit/createPurchaseSuccess',
  createPurchaseFailure = 'audit/createPurchaseFailure',
  setAfterSubmit = 'audit/setAfterSubmit',
  listSaleRequest = 'audit/listSaleRequest',
  listSaleSuccess = 'audit/listSaleSuccess',
  listSaleFailure = 'audit/listSaleFailure',
  createSaleRequest = 'audit/createSaleRequest',
  createSaleSuccess = 'audit/createSaleSuccess',
  createSaleFailure = 'audit/createSaleFailure',
}
const slice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    onChangeDialogId(
      state,
      action: PayloadAction<null | 'audits' | 'head-audit'>
    ) {
      state.dialogId = action.payload;
    },
    listPurchaseRequest(state, action: PayloadAction<PurchasesQueries>) {
      if (action.payload.product) {
        state.purchase = {
          ...state.purchase,
          [action.payload.product]: {
            data: [],
            isLoading: true,
          },
        };
      }
    },
    listPurchaseSuccess(
      state,
      action: PayloadAction<{
        data: Purchase[];
        product: string;
      }>
    ) {
      state.purchase[action.payload.product] = {
        data: action.payload.data,
        isLoading: false,
      };
    },
    listPurchaseFailure(state, action: PayloadAction<{ product: string }>) {
      state.purchase[action.payload.product].isLoading = false;
    },
    createPurchaseRequest(state, _: PayloadAction<AuditField>) {
      state.isCreating = true;
    },
    createPurchaseSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        product: string;
        ids: Purchase[];
        type: AuditType;
        status: AuditStatus;
        comment: string;
      }>
    ) {
      state.isCreating = false;
      state.isAfterSubmit = true;
      const lastData = state.purchase[payload.product];
      const newData = lastData.data.map((item) => {
        const isSame = payload.ids.find((id) => id.id === item.id);
        if (isSame) {
          const audit = isSame.audit;
          return {
            ...isSame,
            audit: {
              ...audit,
              [payload.type === 'auditor' ? 'statusAuditor' : 'statusHead']:
                payload.status,
              [payload.type === 'auditor'
                ? 'commentFromAuditor'
                : 'commentFromHead']: payload.comment,
            },
          };
        }
        return item;
      });
      state.purchase[payload.product] = {
        data: newData,
        isLoading: false,
      };
    },
    createPurchaseFailure(state) {
      state.isCreating = false;
    },
    setAfterSubmit(state, action: PayloadAction<boolean>) {
      state.isAfterSubmit = action.payload;
    },
    listSaleRequest(state, action: PayloadAction<SaleTransactionsQueries>) {
      if (action.payload.product) {
        state.sale[action.payload.product] = {
          data: [],
          isLoading: true,
        };
      }
    },
    listSaleSuccess(
      state,
      action: PayloadAction<{
        data: SaleTransaction[];
        product: string;
      }>
    ) {
      state.sale[action.payload.product] = {
        data: action.payload.data,
        isLoading: false,
      };
    },
    listSaleFailure(state, action: PayloadAction<{ product: string }>) {
      state.sale[action.payload.product].isLoading = false;
    },
    createSaleRequest(state, _: PayloadAction<AuditSaleField>) {
      state.isCreating = true;
    },
    createSaleSuccess(
      state,
      {
        payload,
      }: PayloadAction<{
        product: string;
        ids: SaleTransaction[];
        type: AuditType;
        status: AuditStatus;
        comment: string;
      }>
    ) {
      state.isCreating = false;
      state.isAfterSubmit = true;
      const lastData = state.sale[payload.product];
      const newData = lastData.data.map((item) => {
        const isSame = payload.ids.find((id) => id.id === item.id);
        if (isSame) {
          const audit = isSame.audit;
          return {
            ...isSame,
            audit: {
              ...audit,
              [payload.type === 'auditor' ? 'statusAuditor' : 'statusHead']:
                payload.status,
              [payload.type === 'auditor'
                ? 'commentFromAuditor'
                : 'commentFromHead']: payload.comment,
            },
          };
        }
        return item;
      });
      state.sale[payload.product] = {
        data: newData,
        isLoading: false,
      };
    },
    createSaleFailure(state) {
      state.isCreating = false;
    },
  },
});
export const {
  onChangeDialogId,
  createPurchaseFailure,
  createPurchaseRequest,
  createPurchaseSuccess,
  listPurchaseFailure,
  listPurchaseRequest,
  listPurchaseSuccess,
  setAfterSubmit,
  listSaleRequest,
  listSaleFailure,
  listSaleSuccess,
  createSaleRequest,
  createSaleSuccess,
  createSaleFailure,
} = slice.actions;
export default slice.reducer;
