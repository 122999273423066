import auth from './auth';
import notify from './notify';
import db from './db';
import me from './me';
//Tier
import * as tier from './tier';
import superAdmin from './super-admin';
import admin from './admin';
import data from './data';
import report from './report';
const reducers = {
  auth,
  notify,
  me,
  data,
  db,
  ...tier,
  admin: admin,
  superAdmin: superAdmin,
  report: report,
};

export default reducers;
