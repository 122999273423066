import type { CancelToken, AxiosResponse } from 'axios';
import API from './defaultInstance';
import type {
  CategoriesParams,
  ListCategoriesDataRes,
  CategoryForm,
} from 'models/product';
import type {
  ProductsParams,
  ListProductsDataRes,
  GetProductDataRes,
  ProductField,
  ProductProcessField,
  MoistureForm,
  EstimateForm,
} from 'models/product.v2';
export const listCategoriesAPI = async (
  params: CategoriesParams,
  cancelToken?: CancelToken
) => {
  const res: AxiosResponse<ListCategoriesDataRes> = await API.get(
    `/v2/admin/product/category`,
    {
      params: params,
      cancelToken: cancelToken,
    }
  );
  return res.data;
};
export const createCategoryAPI = async (data: CategoryForm) => {
  const res: AxiosResponse<ListCategoriesDataRes> = await API.post(
    `/v2/admin/product/category`,
    {
      title: data.title,
      name: data.name,
      parentCategory: data.parentCategory || null,
    }
  );
  return res.data;
};
export const updateCategoryAPI = async (id: string, data: CategoryForm) => {
  const res: AxiosResponse<ListCategoriesDataRes> = await API.patch(
    `/v2/admin/product/category/${id}`,
    {
      title: data.title,
      name: data.name,
      parentCategory: data.parentCategory || null,
    }
  );
  return res.data;
};
export const listProductsAPI = async (
  params: ProductsParams,
  cancelToken?: CancelToken
) => {
  const res: AxiosResponse<ListProductsDataRes> = await API.get(
    `/v2/products`,
    {
      params: params,
      cancelToken: cancelToken,
    }
  );
  return res.data;
};
export const getProductsAPI = async (id: string) => {
  const res: AxiosResponse<ListProductsDataRes> = await API.get(
    `/v2/products?name=${id}`
  );
  const data = res.data.products[0];
  if (data) return data;
  throw new Error('id not found.');
};
export const createProductsAPI = async (data: ProductField) => {
  const res: AxiosResponse<GetProductDataRes> = await API.post(
    `/v2/admin/product/products`,
    data
  );
  return res.data.product;
};
export const updateProductsAPI = async (id: string, data: ProductField) => {
  const res: AxiosResponse<GetProductDataRes> = await API.patch(
    `/v2/admin/product/products/${id}`,
    data
  );
  return res.data.product;
};
export const updateProductSizesAPI = async (
  productName: string,
  data: number[]
) => {
  const res: AxiosResponse<ListCategoriesDataRes> = await API.patch(
    `/v2/admin/product/sizes`,
    {
      productName: productName,
      sizes: data,
    }
  );
  return res.data;
};
export const updateProductGradesAPI = async (
  productName: string,
  data: string[]
) => {
  const res: AxiosResponse<ListCategoriesDataRes> = await API.patch(
    `/v2/admin/product/grades-title`,
    {
      productName: productName,
      gradesTitle: data,
    }
  );
  return res.data;
};
export const listProcessAPI = async (id: string, params?: any) => {
  const res: AxiosResponse = await API.get(
    `/v2/admin/product/${id}/process-to-product`,
    {
      params: params,
    }
  );
  return res.data;
};
export const updateProcessAPI = async (data: ProductProcessField[]) => {
  const res: AxiosResponse = await API.post(
    `/v2/admin/product/process-to-product`,
    data
  );
  return res.data;
};
export const deleteProcessAPI = async (id: string) => {
  const res: AxiosResponse = await API.delete(
    `/v2/admin/product/process-to-product/${id}`
  );
  return res.data;
};
export const listLossMoistureAPI = async (id: string, params?: any) => {
  const res: AxiosResponse = await API.get(
    `/v2/admin/product/${id}/loss-moisture-estimate`,
    {
      params: params,
    }
  );
  return res.data;
};
export const createLossMoistureAPI = async (
  productName: string,
  data: MoistureForm[]
) => {
  const res: AxiosResponse = await API.post(
    `/v2/admin/product/${productName}/loss-moisture-estimate`,
    data
  );
  return res.data;
};
export const lisLossEstimateAPI = async (id: string, params?: any) => {
  const res: AxiosResponse = await API.get(
    `/v2/admin/product/${id}/loss-estimate`,
    {
      params: params,
    }
  );
  return res.data;
};
export const updateLossEstimateAPI = async (data: EstimateForm[]) => {
  const res: AxiosResponse = await API.post(
    `/v2/admin/product/loss-estimate`,
    data
  );
  return res.data;
};
export const deleteLossEstimateAPI = async (id: string) => {
  const res: AxiosResponse = await API.delete(
    `/v2/admin/product/loss-estimate/${id}`
  );
  return res.data;
};
