import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  Purchase,
  SummaryEntry,
  QueryParams,
} from 'models/purchase.report.v2';

export interface InitialState {
  summaries: {
    isLoading: boolean;
    entries: SummaryEntry[];
    timelines: number[];
  };
  transactions: {
    isLoading: boolean;
    entries: Purchase[];
    page: number;
    pageSize: number;
    pageTokens: Array<string>;
    isExporting: boolean;
  };
}
const initialState: InitialState = {
  summaries: {
    entries: [],
    isLoading: false,
    timelines: [],
  },
  transactions: {
    entries: [],
    isExporting: false,
    isLoading: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
};
export enum Types {
  listSummariesRequest = 'report/listSummariesRequest',
  listSummariesSuccess = 'report/listSummariesSuccess',
  listSummariesFailure = 'report/listSummariesFailure',
  listTransactionsRequest = 'report/listTransactionsRequest',
  listTransactionsSuccess = 'report/listTransactionsSuccess',
  listTransactionsFailure = 'report/listTransactionsFailure',
  listTransactionsExportRequest = 'report/listTransactionsExportRequest',
  listTransactionsExportSuccess = 'report/listTransactionsExportSuccess',
  listTransactionsExportFailure = 'report/listTransactionsExportFailure',
}
const slice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    listSummariesRequest: (state, _: PayloadAction<QueryParams>) => {
      state.summaries.isLoading = true;
    },
    listSummariesSuccess: (
      state,
      action: PayloadAction<{
        timelines: number[];
        entries: SummaryEntry[];
      }>
    ) => {
      state.summaries.entries = action.payload.entries;
      state.summaries.timelines = action.payload.timelines;
      state.summaries.isLoading = false;
    },
    listSummariesFailure: (state) => {
      state.summaries.isLoading = false;
    },
    listTransactionsRequest: (state, _: PayloadAction<QueryParams>) => {
      state.transactions.isLoading = true;
    },
    listTransactionsSuccess: (state, action: PayloadAction<Purchase[]>) => {
      state.transactions.entries = action.payload;
      state.transactions.isLoading = false;
    },
    listTransactionsFailure: (state) => {
      state.transactions.isLoading = false;
    },
  },
});
export const {
  listSummariesRequest,
  listSummariesSuccess,
  listSummariesFailure,
} = slice.actions;
export default slice.reducer;
