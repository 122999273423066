import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  take,
} from 'redux-saga/effects';
// import { push } from 'redux-first-history';
import { addAlert } from 'store/notify';
import API, { Canceler, createCancelToken } from 'services/defaultInstance';
import { listProcessAPI } from 'services/product';
import * as actions from './index';
import {
  GetStocksResponse,
  CreateStockQualityCheck,
  GetStockResponse,
  QcProcess,
} from 'models';
import type { StockProcess, ProcessStep, StockQualityCheck } from 'models';
import type { ListProductProcessDataRes } from 'models/product.v2';
import { findErrorToData } from 'utils';
import { getStockStatus, batchGetData } from 'utils/options';
import { RootState } from 'store/index';

let cancels: Canceler[] = [];

type GetStocksRequest = ReturnType<typeof actions.getStocksRequest>;
function* getStocks({ payload }: GetStocksRequest) {
  const {
    auth,
    inventoryStocksTransform: { list },
  }: RootState = yield select((state) => state);
  let query = `status=TRANSFORMING&tier=${
    auth.accept.tier?.name || ''
  }&pageSize=${payload.pageSize ? payload.pageSize : list.pageSize}`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }
  let dataPayload: actions.GetStocksSuccess = {
    stocks: [],
    pageTokens: [...list.pageTokens],
  };
  try {
    const { data }: GetStocksResponse = yield call(
      API.get,
      `/v1/inventory/stocks?${query}`
    );
    dataPayload.stocks = data.stocks;
    const pageToken = list.pageTokens.find((p) => p === data.nextPageToken);
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== list.pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    yield put(actions.getStocksSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getStocksFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type CreateQualityCheckRequest = ReturnType<
  typeof actions.createQualityCheckRequest
>;
function* createQualityCheck({ payload }: CreateQualityCheckRequest) {
  const {
    inventoryStocksTransform: {
      get: { stock },
    },
  }: RootState = yield select((state) => state);
  const cancelToken: Canceler = yield createCancelToken();
  cancels.push(cancelToken);
  if (!stock) {
    yield put(actions.createQualityCheckFailure(''));
    return;
  }
  const data: CreateStockQualityCheck = {
    remark: payload.remark || '',
    impurity: payload.impurity,
    moisturePercent: payload.moisturePercent,
    starchPercent: payload.starchPercent,
    stockId: stock.id || '',
    productName: payload.productName,
  };
  try {
    yield call(API.post, `/v2/inventory/stocks/qc`, data);
    yield put(actions.createQualityCheckSuccess(data));
    yield put(
      addAlert({
        message: 'ສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
    yield put(actions.listProcessStepRequest(stock));
  } catch (error: any) {
    cancels = [];
    const errorData = findErrorToData({ error: error });
    yield put(actions.createQualityCheckFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getStock({ payload }: ReturnType<typeof actions.getStockRequest>) {
  try {
    const { data }: GetStockResponse = yield call(
      API.get,
      `/v1/inventory/stocks/${payload}`
    );
    yield put(actions.getStockSuccess(data.stock));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getStockFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type CreateCompleteTransformRequest = ReturnType<
  typeof actions.createCompleteTransformRequest
>;
function* createCompleteTransform({ payload }: CreateCompleteTransformRequest) {
  const {
    inventoryStocksTransform: {
      get: { stock },
    },
  }: RootState = yield select((state) => state);
  const cancelToken: Canceler = yield createCancelToken();
  cancels.push(cancelToken);
  try {
    yield call(API.post, `/v1/inventory/stocks-completeTransformation`, {
      stockId: payload,
    });
    yield put(actions.createCompleteTransformSuccess());
    yield put(
      addAlert({
        message: 'ສຳເລັດການແປຮູບແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
    if (stock)
      yield put(actions.getStockSuccess({ ...stock, status: 'COMPLETE' }));
  } catch (error: any) {
    cancels = [];
    const errorData = findErrorToData({ error: error });
    yield put(actions.createCompleteTransformFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listQc(stockId: string) {
  try {
    const qc: StockQualityCheck[] = yield call(batchGetData, {
      httpRequest: (q) => API.get(`/v2/inventory/stocks/${stockId}/qc${q}`),
      keyResponse: 'stocksQualityCheck',
    });
    const qcObject = qc
      .sort(
        (a, b) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .reduce((items, item) => {
        const process = items[item.productName] || {
          title: item.productNameTitle,
          name: item.productName,
          qc: [],
        };
        return {
          ...items,
          [item.productName]: {
            title: process.title,
            name: process.name,
            qc: [...process.qc, item],
          },
        };
      }, {} as QcProcess);
    yield put(actions.setQc(qcObject));
  } catch (error) {
    yield put(actions.qcError());
    const errorData = findErrorToData({ error: error });
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type QcAction =
  | ReturnType<typeof actions.setQc>
  | ReturnType<typeof actions.qcError>;
function* listProcessStep({
  payload,
}: ReturnType<typeof actions.listProcessStepRequest>) {
  let process: StockProcess = {
    product: payload.product,
    qc: {},
    step: {},
    currentStep: 0,
  };
  yield fork(listQc, payload.id);
  const qc: QcAction = yield take([actions.Types.setQc, actions.Types.qcError]);
  if (qc.type === actions.Types.qcError) {
    yield put(actions.listProcessStepFailure());
    return;
  }
  if (qc.type === actions.Types.setQc && qc.payload) {
    process.qc = qc.payload;
  }
  if (getStockStatus(payload) === 'ສຳເລັດແລ້ວ') {
    const steps = Object.values(process.qc).reduce((step, qcItem) => {
      const value = step[qcItem.name];
      if (value) return step;
      return {
        ...step,
        [qcItem.name]: {
          name: qcItem.name,
          title: qcItem.title,
          formula: 0,
          isProcess: false,
        },
      };
    }, {} as ProcessStep);
    process.step = steps;
    process.currentStep = -1;
    yield put(actions.listProcessStepSuccess(process));
    return;
  }
  try {
    const listProcess: ListProductProcessDataRes = yield call(
      listProcessAPI,
      payload.product.name
    );
    const isLastToProduct = (payload?.toProduct || '').length === 0;
    const listProcessIndex = listProcess.findIndex(
      (item) => item.processToProduct === payload.toProduct
    );
    console.log(listProcessIndex, 'listProcessIndex');
    const steps = listProcess.reduce((step, item, index) => {
      const value = step[item.productName];
      if (value) return step;
      return {
        ...step,
        [item.processToProduct]: {
          name: item.processToProduct,
          title: item.processToProductTitle,
          formula: item.formula,
          isProcess: isLastToProduct && index === 0 ? true : false,
        },
      };
    }, {} as ProcessStep);
    // const curr = Object.values(steps).findIndex(
    //   (step) => step.name === payload.toProduct
    // );
    process.step = steps;
    process.currentStep = listProcessIndex + 1;
    // if ((payload?.toProduct || '').length === 0) {
    //   process.currentStep = 0;
    // }
    yield put(actions.listProcessStepSuccess(process));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listProcessStepFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* closeStepProcess({
  payload,
}: ReturnType<typeof actions.closeStepProcessRequest>) {
  const {
    inventoryStocksTransform: {
      get: { stock },
    },
  }: RootState = yield select((state) => state);
  if (!stock) {
    yield put(actions.closeStepProcessFailure());
    return;
  }
  try {
    yield call(API.post, `/v2/inventory/stocks/process`, payload);
    yield put(actions.closeStepProcessSuccess());
    yield put(
      addAlert({
        message: 'ສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
    yield put(
      actions.getStockSuccess({ ...stock, toProduct: payload.toProduct })
    );
    yield put(actions.listProcessStepRequest(stock));
  } catch (error: any) {
    cancels = [];
    const errorData = findErrorToData({ error: error });
    yield put(actions.closeStepProcessFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetStocks() {
  yield takeLatest(actions.Types.getStocksRequest, getStocks);
}
function* watchCreateQualityCheck() {
  yield takeLatest(actions.Types.createQualityCheckRequest, createQualityCheck);
}
function* watchGetStock() {
  yield takeLatest(actions.Types.getStockRequest, getStock);
}
function* watchCreateCompleteTransform() {
  yield takeLatest(
    actions.Types.createCompleteTransformRequest,
    createCompleteTransform
  );
}
function* watchListProcess() {
  yield takeLatest(actions.Types.listProcessStepRequest, listProcessStep);
}
function* watchCloseStepProcess() {
  yield takeLatest(actions.Types.closeStepProcessRequest, closeStepProcess);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetStocks),
    fork(watchListProcess),
    fork(watchCreateQualityCheck),
    fork(watchGetStock),
    fork(watchCreateCompleteTransform),
    fork(watchCloseStepProcess),
  ]);
}
export default saga;
