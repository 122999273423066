import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  takeLeading,
} from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { nanoid } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { addAlert } from 'store/notify';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import { findErrorToData, setPageInfo, prevOrNextCursorPage } from 'utils';
import { RootState } from 'store';
import {
  GetCustomersResponse,
  GetSaleTransactionsResponse,
  GetSaleInvoicesResponse,
  CreateSaleTransaction,
  GetLotsResponse,
  CreateTransactionLots,
  GetSaleTransactionResponse,
  GetSaleTransactionLotsResponse,
  WalletAccount,
  GetWalletAccountsResponse,
  CreateTransactionLotTransport,
  CreateTransactionLotCrossDock,
  CreateTransactionLotShipping,
  CreateTransactionLotSource,
  CreateTransactionLotDestination,
  CreateSaleTransactionSync,
  PostTransactionSyncResponse,
  GetSaleInvoiceResponse,
  SaleTransactionsQueries,
  DefaultQueries,
} from 'models';

let cancels: Canceler[] = [];

function* getTransactions({
  payload,
}: ReturnType<typeof actions.getSaleTransactionsRequest>) {
  const {
    saleTransactions: { sales },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
  const sale = sales[payloadParams?.product || ''] ?? {
    data: [],
    isLoading: false,
    error: null,
    page: 1,
    pageSize: 25,
    pageTokens: [],
  };
  if (!payloadParams?.product) {
    yield put(actions.getSaleTransactionFailure(payload.product || ''));
    return;
  }
  try {
    const { page, pageSize, startedAt, endedAt, ...payloadParams } = payload;
    let params: SaleTransactionsQueries & DefaultQueries = {
      ...payloadParams,
      pageSize: pageSize || sale.pageSize,
    };
    if (startedAt && !endedAt) {
      params.createdAt = startedAt;
    }
    if (startedAt && endedAt) {
      params.startedAt = startedAt;
      params.endedAt = endedAt;
    }
    const { data }: GetSaleTransactionsResponse = yield call(
      API.get,
      `/v1/sale/transactions`,
      {
        cancelToken: cancelToken.token,
        params: params,
      }
    );
    const pageInfo = setPageInfo(
      {
        page: sale?.page || 1,
        pageSize: sale?.pageSize | 25,
        pageTokens: sale?.pageTokens || [],
      },
      {
        page: page,
        pageSize: pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    const dataPayload: actions.GetTransactionsSuccess = {
      ...pageInfo,
      transactions: data.transactions,
      product: payloadParams.product,
    };
    yield put(actions.getSaleTransactionsSuccess(dataPayload));
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSaleTransactionsFailure(payload.product || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeTransactionsListPage({
  payload,
}: ReturnType<typeof actions.onChangeTransactionsListPage>) {
  const { sales }: actions.InitialState = yield select(
    (state: RootState) => state.saleTransactions
  );
  const sale = sales[payload.product || ''];
  if (!sale) {
    yield put(actions.getSaleTransactionFailure(payload.product || ''));
    return;
  }
  let params: SaleTransactionsQueries & DefaultQueries = {
    ...payload,
  };
  const { page, pageTokens } = sale;
  const pageInfo = prevOrNextCursorPage({
    nextOrPrev: payload.nextOrPrev === 'prev' ? 'prev' : 'next',
    page,
    pageTokens,
  });
  yield put(actions.getSaleTransactionsRequest({ ...params, ...pageInfo }));
}
function* onChangeTransactionsListPageSize({
  payload,
}: ReturnType<typeof actions.onChangeTransactionsListPageSize>) {
  yield put(actions.getSaleTransactionsRequest({ ...payload }));
}

function* getCustomers() {
  const { auth }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let url = `v1/tiers/${auth.accept.tier?.name || ''}/customers`;
  try {
    const { data }: GetCustomersResponse = yield call(API.get, url, {
      cancelToken: cancelToken.token,
    });
    const options = data.customers.map((customer) => ({
      label: customer.displayName,
      value: customer.id,
    }));
    yield put(actions.getCustomersSuccess(options));
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getCustomersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getInvoices() {
  const { auth }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let url = `v1/sale/invoices?tier=${
    auth.accept.tier?.name || ''
  }&pageSize=250&status=PENDING`;
  try {
    const { data }: GetSaleInvoicesResponse = yield call(API.get, url, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.getInvoicesSuccess(data.invoices));
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getInvoicesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* createSaleTransaction({
  payload,
}: ReturnType<typeof actions.createSaleTransactionRequest>) {
  const {
    auth: {
      accept: { product: productsList, tier },
    },
    saleTransactions: { invoicesList },
    router: { location },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let url = `v1/sale/transactions`;
  const invoice = invoicesList.invoices.find((i) => i.id === payload.invoiceId);
  const product = (productsList.data[tier?.name || ''] || []).find(
    (p) => p.productName === payload.product.title
  );
  const transaction: CreateSaleTransaction = {
    customer: {
      id: invoice?.customer.id || '',
      displayName: invoice?.customer.displayName || '',
    },
    invoiceId: payload.invoiceId,
    product: {
      name: product?.productName || '',
      title: product?.productTitle || '',
    },
    referenceId: nanoid(18),
    shippingLocation: payload.shippingLocation,
    tier: tier?.name || '',
    vihicle: {
      code: payload.vihicle.code,
      numberPlate: payload.vihicle.numberPlate || '',
      tier: tier?.name || '',
      type: payload.vihicle.vihicleType,
    },
  };
  try {
    yield call(API.post, url, transaction, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.createSaleTransactionSuccess());
    cancels = [];
    yield put(
      push({
        pathname: (location?.pathname || '').replace('/create', ''),
        search: location?.search,
      })
    );
    yield put(
      addAlert({
        message: 'ເພີ່ມສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createSaleTransactionFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* getLots() {
  const {
    auth,
    saleTransactions: { addLots },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let url = `/v1/inventory/lots?tier=${
    auth.accept.tier?.name || ''
  }&pageSize=250&status=READY&product=${
    addLots.transaction?.product.name || ''
  }`;
  try {
    const { data }: GetLotsResponse = yield call(API.get, url, {
      cancelToken: cancelToken.token,
    });
    const lots = data.lots.filter(
      (lot) => lot.product.name === addLots.transaction?.product.name || ''
    );
    yield put(actions.getLotsSuccess(lots));
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getLotsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* addLots({
  payload,
}: ReturnType<typeof actions.createTransactionLotsRequest>) {
  const {
    saleTransactions: {
      addLots: { transaction, lots },
    },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  const transactionLots: CreateTransactionLots = {
    txId: transaction?.id || '',
    lots: payload.lotNumbers.map((number) => {
      const lot = lots.find((l) => l.number === number);
      return {
        id: lot?.id || '',
        number: lot?.number || '',
      };
    }),
  };
  try {
    yield call(API.post, `/v1/sale/transactions/lots`, transactionLots, {
      cancelToken: cancelToken.token,
    });

    yield put(actions.createTransactionLotsSuccess());
    yield put(
      addAlert({
        message: 'ເພີ່ມສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createTransactionLotsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getTransaction({
  payload,
}: ReturnType<typeof actions.getSaleTransactionRequest>) {
  const {
    auth: {
      accept: { resourceAccess, tier },
    },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  try {
    const transactionRes: GetSaleTransactionResponse = yield call(
      API.get,
      `/v1/sale/transactions/${payload}`,
      {
        cancelToken: cancelToken.token,
      }
    );
    const invoiceRes: GetSaleInvoiceResponse = yield call(
      API.get,
      `/v1/sale/invoices/${transactionRes.data.transaction.invoiceId}`,
      {
        cancelToken: cancelToken.token,
      }
    );
    const transactionLotsRes: GetSaleTransactionLotsResponse = yield call(
      API.get,
      `/v1/sale/transactions/${payload}/lots`,
      {
        cancelToken: cancelToken.token,
      }
    );
    let wallets: WalletAccount[] = [];
    if (resourceAccess['wallets'].read) {
      const walletsRes: GetWalletAccountsResponse = yield call(
        API.get,
        `/v1/tiers/${tier?.name || ''}/wallets`,
        {
          cancelToken: cancelToken.token,
        }
      );
      wallets = walletsRes.data.wallets;
    }

    yield put(
      actions.getSaleTransactionSuccess({
        transaction: {
          ...transactionRes.data.transaction,
        },
        lots: transactionLotsRes.data.lots,
        wallets,
        invoiceNumber: invoiceRes.data.invoice.number,
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getSaleTransactionFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* paymentTransport({
  payload,
}: ReturnType<typeof actions.paymentTransportRequest>) {
  const {
    saleTransactions: { transactionDetail },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  const wallet = transactionDetail.wallets.find(
    (w) => w.number === payload.accountNumber
  );
  const detail: CreateTransactionLotTransport = {
    txId: transactionDetail.transaction?.id || '',
    accountNumber: payload.accountNumber,
    description: payload.description,
    referenceId: nanoid(18),
    lots: payload.lots.map((lot) => ({
      id: lot.id,
      transportAmount: {
        amount: lot.transportAmount,
        currency: wallet?.currency || 'LAK',
      },
      transportAmountKg: {
        amount: '0',
        currency: wallet?.currency || 'LAK',
      },
    })),
  };
  try {
    yield call(API.post, `/v1/sale/transactions-paymentTransportFee`, detail, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.paymentTransportSuccess());
    yield put(
      addAlert({
        message: 'ການຈ່າຍສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
    yield put(
      actions.getSaleTransactionRequest(transactionDetail.transaction?.id || '')
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.paymentTransportFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* paymentShipping({
  payload,
}: ReturnType<typeof actions.paymentShippingRequest>) {
  const {
    saleTransactions: { transactionDetail },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  const wallet = transactionDetail.wallets.find(
    (w) => w.number === payload.accountNumber
  );
  const detail: CreateTransactionLotShipping = {
    txId: transactionDetail.transaction?.id || '',
    accountNumber: payload.accountNumber,
    description: payload.description,
    referenceId: nanoid(18),
    lots: payload.lots.map((lot) => ({
      id: lot.id,
      shippingAmount: {
        amount: lot.shippingAmount,
        currency: wallet?.currency || 'LAK',
      },
    })),
  };
  try {
    yield call(API.post, `/v1/sale/transactions-paymentShippingFee`, detail, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.paymentTransportSuccess());
    yield put(
      addAlert({
        message: 'ການຈ່າຍສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
    yield put(
      actions.getSaleTransactionRequest(transactionDetail.transaction?.id || '')
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.paymentTransportFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* paymentCrossDock({
  payload,
}: ReturnType<typeof actions.paymentCrossDockRequest>) {
  const {
    saleTransactions: { transactionDetail },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  const wallet = transactionDetail.wallets.find(
    (w) => w.number === payload.accountNumber
  );
  const detail: CreateTransactionLotCrossDock = {
    txId: transactionDetail.transaction?.id || '',
    accountNumber: payload.accountNumber,
    description: payload.description,
    referenceId: nanoid(18),
    lots: payload.lots.map((lot) => ({
      id: lot.id,
      crossDockAmount: {
        amount: lot.crossDockAmount,
        currency: wallet?.currency || 'LAK',
      },
    })),
  };
  try {
    yield call(API.post, `/v1/sale/transactions-paymentCrossDockFee`, detail, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.paymentTransportSuccess());
    yield put(
      addAlert({
        message: 'ການຈ່າຍສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
    yield put(
      actions.getSaleTransactionRequest(transactionDetail.transaction?.id || '')
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.paymentTransportFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateInvoice() {
  const {
    saleTransactions: {
      transactionDetail: { transaction },
    },
  }: RootState = yield select((state: RootState) => state);
  try {
    yield call(API.post, `/v1/sale/invoices-summary`, {
      invoiceId: transaction?.invoiceId || '',
    });

    yield put(actions.updateInvoiceSuccess(''));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateInvoiceFailure('updateInvoiceFailure'));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateSource({
  payload,
}: ReturnType<typeof actions.updateSourceRequest>) {
  const {
    saleTransactions: {
      transactionDetail: { transaction },
    },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  const weightKg = payload.lots.reduce(
    (sum, lot) => sum + Number.parseFloat(lot.sourceWeightKg),
    0
  );
  const sourceWeightKg = Number.parseFloat(transaction?.sourceWeightKg || '0');
  if (weightKg > sourceWeightKg || weightKg < sourceWeightKg) {
    yield put(actions.updateSourceFailure('ກະລຸນາປ້ອນນ້ຳໜັກໃຫ້ຖືກຕ້ອງ'));
    yield put(
      addAlert({
        message: 'ກະລຸນາປ້ອນນ້ຳໜັກໃຫ້ຖືກຕ້ອງ.',
        serviceType: 'snackbar',
        type: 'warning',
      })
    );
    return;
  }
  const source: CreateTransactionLotSource = {
    txId: transaction?.id || '',
    lots: payload.lots.map((lot) => ({
      id: lot.id,
      sourceWeightKg: lot.sourceWeightKg,
      sourceAmount: {
        amount: lot.sourceAmount,
        currency: transaction?.sourcePrice.currency || 'LAK',
      },
      sourcePrice: {
        amount: transaction?.sourcePrice.amount || '0',
        currency: transaction?.sourcePrice.currency || 'LAK',
      },
    })),
  };
  try {
    yield call(API.post, `/v1/sale/transactions/lots-source`, source, {
      cancelToken: cancelToken.token,
    });
    yield put(
      addAlert({
        message: 'ເພີ່ມສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(actions.updateSourceSuccess());
    cancels = [];
    yield put(actions.getSaleTransactionRequest(transaction?.id || ''));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateSourceFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* updateDestination({
  payload,
}: ReturnType<typeof actions.updateDestinationRequest>) {
  const {
    saleTransactions: {
      transactionDetail: { transaction },
    },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  const source: CreateTransactionLotDestination = {
    txId: transaction?.id || '',
    lots: payload.lots.map((lot) => ({
      id: lot.id,
      destinationWeightKg: lot.destinationWeightKg,
      destinationAmount: {
        amount: lot.destinationAmount,
        currency: transaction?.sourcePrice.currency || '',
      },
      destinationPrice: {
        amount: payload.price,
        currency: transaction?.sourcePrice.currency || '',
      },
    })),
  };
  try {
    yield call(API.post, `/v1/sale/transactions-destination`, source, {
      cancelToken: cancelToken.token,
    });
    yield put(actions.updateDestinationSuccess());
    yield put(actions.updateInvoiceRequest());
    // const { type }: UpdateInvoice = yield take([
    //   actions.updateInvoiceSuccess,
    //   actions.updateInvoiceFailure,
    // ]);
    yield put(actions.updateSourceSuccess());
    yield put(
      addAlert({
        message: 'ເພີ່ມສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
    yield put(actions.getSaleTransactionRequest(transaction?.id || ''));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateDestinationFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* addQuantity({
  payload,
}: ReturnType<typeof actions.addQuantityRequest>) {
  const {
    saleTransactions: {
      transactionDetail: { transaction },
    },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  let form = new FormData();
  form.append('txId', transaction?.id || '');
  form.append('impurity', payload.impurity);
  form.append('moisturePercent', payload.moisturePercent);
  form.append('starchPercent', payload.starchPercent);
  const photoUrls = payload.photoUrls;
  if (photoUrls && photoUrls !== undefined) {
    Object.keys(photoUrls).forEach((photoUrl) => {
      form.append(`photos`, photoUrls[photoUrl as any] as any);
    });
  }
  try {
    yield call(API.post, `/v1/sale/transactions-qualityChecks`, form, {
      cancelToken: cancelToken.token,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    yield put(actions.addQuantitySuccess());
    yield put(
      addAlert({
        message: 'ເພີ່ມສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
    yield put(actions.getSaleTransactionRequest(transaction?.id || ''));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.addQuantityFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* createTransactionSync({
  payload,
}: ReturnType<typeof actions.createTransactionSyncRequest>) {
  const {
    auth: {
      accept: { tier },
    },
  }: RootState = yield select((state: RootState) => state);
  const cancelToken = CancelToken.source();
  cancels.push(cancelToken.cancel);
  const transaction: CreateSaleTransactionSync = {
    amount: {
      amount: payload.amount,
      currency: payload.currency,
    },
    number: payload.number,
    price: {
      amount: payload.price,
      currency: payload.currency,
    },
    referenceId: nanoid(18),
    vehicleCode: payload.vehicleCode,
    volumeKg: payload.volumeKg,
    tier: {
      name: tier?.name || '',
      title: tier?.title || '',
    },
  };
  try {
    const { data }: PostTransactionSyncResponse = yield call(
      API.post,
      `/v1/sale/transactions-fromSync`,
      transaction,
      {
        cancelToken: cancelToken.token,
      }
    );
    yield put(actions.createTransactionSyncSuccess(data.tx));
    yield put(
      addAlert({
        message: 'ເພີ່ມສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    cancels = [];
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createTransactionSyncFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* cancelPayment() {
  const { transactionDetail, cancelPayment }: actions.InitialState =
    yield select((state) => state.saleTransactions);
  if (!transactionDetail.transaction) {
    yield put(actions.cancelPaymentFailure(''));
    return;
  }
  let transaction: actions.GetSaleTransactionSuccess = {
    transaction: { ...transactionDetail.transaction },
    lots: transactionDetail.lots,
    wallets: transactionDetail.wallets,
    invoiceNumber: transactionDetail.invoiceNumber,
  };
  let message = '';
  try {
    if (cancelPayment === 'crossDock') {
      yield call(API.post, `/v1/sale/transactions-refundPaymentCrossDockFee`, {
        txId: transactionDetail.transaction?.id || '',
      });
      transaction.transaction = {
        ...transaction.transaction,
        crossDockAmount: {
          amount: '',
          currency: '',
        },
        crossDockStatus: 'PENDING',
      };
      transaction.lots = transaction.lots.map((lot) => ({
        ...lot,
        crossDockAmount: {
          amount: '',
          currency: '',
        },
        crossDockStatus: 'PENDING',
      }));
      message = 'ຍົກເລີກຈ່າຍຄ່າຄຽນຖ່າຍສຳເລັດແລ້ວ';
    }
    if (cancelPayment === 'shipping') {
      yield call(API.post, `/v1/sale/transactions-refundPaymentShippingFee`, {
        txId: transactionDetail.transaction?.id || '',
      });
      transaction.transaction = {
        ...transaction.transaction,
        shippingAmount: {
          amount: '',
          currency: '',
        },
        shippingStatus: 'PENDING',
      };
      transaction.lots = transaction.lots.map((lot) => ({
        ...lot,
        shippingAmount: {
          amount: '',
          currency: '',
        },
        shippingStatus: 'PENDING',
      }));
      message = 'ຍົກເລີກຈ່າຍຄ່າແລ່ນເອກະສານສຳເລັດແລ້ວ';
    }
    if (cancelPayment === 'transport') {
      yield call(API.post, `/v1/sale/transactions-refundPaymentTransportFee`, {
        txId: transactionDetail.transaction?.id || '',
      });
      transaction.transaction = {
        ...transaction.transaction,
        transportAmount: {
          amount: '',
          currency: '',
        },
        transportStatus: 'PENDING',
        transportAmountKg: {
          amount: '',
          currency: '',
        },
      };
      transaction.lots = transaction.lots.map((lot) => ({
        ...lot,
        transportAmount: {
          amount: '',
          currency: '',
        },
        transportStatus: 'PENDING',
        transportAmountKg: {
          amount: '',
          currency: '',
        },
      }));
      message = 'ຍົກເລີກຈ່າຍຄ່າຂົນສົ່ງສຳເລັດແລ້ວ';
    }
    yield put(
      addAlert({
        message: message,
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(actions.cancelPaymentSuccess(transaction));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.cancelPaymentFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* exportCsv({ payload }: ReturnType<typeof actions.exportCsvRequest>) {
  try {
    let queries: { [key: string]: string } = {};
    if (payload?.startedAt && !payload.endedAt) {
      queries.createdAt = payload.startedAt;
    }
    if (payload.startedAt && payload.endedAt) {
      queries.startedAt = payload.startedAt;
      queries.endedAt = payload.endedAt;
    }
    if (payload.customer) {
      queries.customer = payload.customer;
    }
    if (payload.tier) {
      queries.tier = payload.tier;
    }
    if (payload.product) {
      queries.product = payload.product;
    }
    if (payload.number) {
      queries.number = payload.number;
    }
    const { data } = yield call(
      API.post,
      `/v1/sale/transactions-exportCsv`,
      {},
      {
        params: queries,
      }
    );
    const blob = new Blob(['\uFEFF' + data], {
      type: 'text/csv; charset=utf-8',
    });
    yield saveAs(blob, `ລາຍການຂາຍ${moment().format('DD/MM/YYYY')}.csv`);
    yield put(actions.exportCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* openCreateSaleTransactionPage() {
  yield put(actions.getCustomersRequest());
  yield put(actions.getInvoicesRequest());
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetCustomers() {
  yield takeLatest(actions.Types.getCustomersRequest, getCustomers);
}
function* watchGetInvoices() {
  yield takeLatest(actions.Types.getInvoicesRequest, getInvoices);
}
function* watchGetTransactions() {
  yield takeLatest(actions.Types.getSaleTransactionsRequest, getTransactions);
}
function* watchOnChangeTransactionsListPage() {
  yield takeLatest(
    actions.Types.onChangeTransactionsListPage,
    onChangeTransactionsListPage
  );
}
function* watchOnChangeTransactionsListPageSize() {
  yield takeLatest(
    actions.Types.onChangeTransactionsListPageSize,
    onChangeTransactionsListPageSize
  );
}
function* watchOpenCreateSaleTransactionPage() {
  yield takeLatest(
    actions.Types.openCreateSaleTransactionPage,
    openCreateSaleTransactionPage
  );
}
function* watchCreateSaleTransaction() {
  yield takeLatest(
    actions.Types.createSaleTransactionRequest,
    createSaleTransaction
  );
}
function* watchOpenAddLotsDialog() {
  yield takeLatest(actions.Types.openAddLotsDialog, function* () {
    const {
      auth: {
        accept: { resourceAccess },
      },
    }: RootState = yield select((state: RootState) => state);
    if (resourceAccess['inventories'].read) {
      yield put(actions.getLotsRequest());
    }
  });
}
function* watchGetLots() {
  yield takeLatest(actions.Types.getLotsRequest, getLots);
}
function* watchAddLots() {
  yield takeLeading(actions.Types.createTransactionLotsRequest, addLots);
}
function* watchGetTransaction() {
  yield takeLatest(actions.Types.getSaleTransactionRequest, getTransaction);
}
function* watchPaymentTransport() {
  yield takeLeading(actions.Types.paymentTransportRequest, paymentTransport);
}
function* watchPaymentShipping() {
  yield takeLeading(actions.Types.paymentShippingRequest, paymentShipping);
}
function* watchPaymentCrossDock() {
  yield takeLeading(actions.Types.paymentCrossDockRequest, paymentCrossDock);
}
function* watchUpdateSource() {
  yield takeLeading(actions.Types.updateSourceRequest, updateSource);
}
function* watchUpdateDestination() {
  yield takeLeading(actions.Types.updateDestinationRequest, updateDestination);
}
function* watchAddQuantity() {
  yield takeLeading(actions.Types.addQuantityRequest, addQuantity);
}
function* watchCreateTransactionSync() {
  yield takeLeading(
    actions.Types.createTransactionSyncRequest,
    createTransactionSync
  );
}
function* watchCancelPayment() {
  yield takeLeading(actions.Types.cancelPaymentRequest, cancelPayment);
}
function* watchUpdateInvoice() {
  yield takeLeading(actions.Types.updateInvoiceRequest, updateInvoice);
}

function* watchExportCsv() {
  yield takeLatest(actions.Types.exportCsvRequest, exportCsv);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetCustomers),
    fork(watchGetInvoices),
    fork(watchGetTransactions),
    fork(watchOnChangeTransactionsListPage),
    fork(watchOnChangeTransactionsListPageSize),
    fork(watchOpenCreateSaleTransactionPage),
    fork(watchCreateSaleTransaction),
    fork(watchOpenAddLotsDialog),
    fork(watchGetLots),
    fork(watchAddLots),
    fork(watchGetTransaction),
    fork(watchPaymentTransport),
    fork(watchPaymentShipping),
    fork(watchPaymentCrossDock),
    fork(watchUpdateSource),
    fork(watchUpdateDestination),
    fork(watchAddQuantity),
    fork(watchCreateTransactionSync),
    fork(watchCancelPayment),
    fork(watchUpdateInvoice),
    fork(watchExportCsv),
  ]);
}
export default saga;
