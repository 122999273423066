import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  RangeProductPrice,
  MoistureProductPrices,
  MoistureProductPriceAPI,
  PostMoistureProductPricesResponse,
} from 'models';
import { findErrorToData, getAllDataWithCursorNextPage } from 'utils';
import { RootState } from 'store/index';
import moment from 'moment';
let cancels: Canceler[] = [];

function* getProductPricesToday() {
  const { auth }: RootState = yield select((state) => state);
  let params: any = {
    tier: auth.accept.tier?.name || '',
    status: 'PENDING,APPROVE',
    groupIDNotEmpty: true,
  };
  try {
    const data: MoistureProductPriceAPI[] = yield getAllDataWithCursorNextPage<
      MoistureProductPriceAPI[],
      'purchasePrices'
    >({
      httpRequest: (queries) =>
        API.get(`/v1/tiers/moisture-purchase-prices${queries}`, {
          params: params,
        }),
      keyResponse: 'purchasePrices',
    });
    const prices = data.reduce((entries, price) => {
      const getEntry = entries[price.groupID] ?? {
        groupID: price.groupID,
        tier: price.tier,
        product: price.product,
        status: price.status,
        currency: price.price.currency,
        createdAt: price.createdAt,
        updatedAt: price.updatedAt,
        ranges: [],
      };
      return {
        ...entries,
        [price.groupID]: {
          ...getEntry,
          ranges: [
            ...getEntry.ranges,
            {
              remark: price.description,
              moisture: price.moisture,
              price: price.price.amount,
            },
          ],
        },
      };
    }, {} as MoistureProductPrices);
    yield put(actions.getProductPricesToDaySuccess(prices));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getProductPricesToDayFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

type GetProductPricesRequest = ReturnType<
  typeof actions.getProductPricesRequest
>;
function* getProductPrices({ payload }: GetProductPricesRequest) {
  let params: any = {
    tier: payload.tier || '',
    groupIDNotEmpty: true,
  };

  if (payload.status) {
    params.status = payload.status;
  }
  if (payload.createdAt) {
    const createdAt = moment(payload.createdAt, 'YYYY-MM-DDTHH:mm:ss').format(
      'YYYY-MM-DDTHH:mm:ssZ'
    );
    params.createdAt = createdAt;
  }
  try {
    const data: MoistureProductPriceAPI[] = yield getAllDataWithCursorNextPage<
      MoistureProductPriceAPI[],
      'purchasePrices'
    >({
      httpRequest: (queries) =>
        API.get(`/v1/tiers/moisture-purchase-prices${queries}`, {
          params: params,
        }),
      keyResponse: 'purchasePrices',
    });
    const prices = data.reduce((entries, price) => {
      const getEntry = entries[price.groupID] ?? {
        groupID: price.groupID,
        tier: price.tier,
        product: price.product,
        status: price.status,
        currency: price.price.currency,
        createdAt: price.createdAt,
        updatedAt: price.updatedAt,
        ranges: [],
      };
      return {
        ...entries,
        [price.groupID]: {
          ...getEntry,
          ranges: [
            ...getEntry.ranges,
            {
              remark: price.description,
              moisture: price.moisture,
              price: price.price.amount,
            },
          ],
        },
      };
    }, {} as MoistureProductPrices);
    yield put(
      actions.getProductPricesSuccess({
        prices: prices,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getProductPricesFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type ProductPricesOnChangePage = ReturnType<
  typeof actions.productPricesOnChangePage
>;
function* productPricesOnChangePage({ payload }: ProductPricesOnChangePage) {
  const {
    statement: { page, pageTokens },
  }: actions.InitialState = yield select((state) => state.tierProductPrice);
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getProductPricesRequest(cloneFilters));
}
type StatementOnChangePageSize = ReturnType<
  typeof actions.productPricesOnChangePageSize
>;
function* productPricesOnChangePageSize({
  payload,
}: StatementOnChangePageSize) {
  yield put(actions.getProductPricesRequest(payload));
}
type CreateProductPriceAction = ReturnType<
  typeof actions.createProductPriceRequest
>;
function* createProductPrice({ payload }: CreateProductPriceAction) {
  const {
    auth: {
      accept: { tier, product },
    },
  }: RootState = yield select((state) => state);
  const productObject = (product.data[tier?.name || ''] || []).find(
    (p) => p.productName === payload.productId
  );
  if (!productObject) {
    yield put(actions.createProductPriceFailure('Product not found.'));
    return;
  }
  const prices: RangeProductPrice[] = payload.ranges.reduce((ranges, range) => {
    return [
      ...ranges,
      {
        description: range.remark || '',
        moisture: range.moisture,
        price: {
          amount: range.price.toString(),
          currency: payload.currency,
        },
        product: {
          name: productObject?.productName,
          title: productObject.productTitle,
        },
        tier: tier?.name || '',
      },
    ];
  }, [] as RangeProductPrice[]);
  try {
    const { data }: PostMoistureProductPricesResponse = yield call(
      API.post,
      '/v1/tiers/moisture-purchase-prices',
      prices.reverse()
    );
    const pricesData = data.reduce((entries, price) => {
      const getEntry = entries[price.groupID] ?? {
        groupID: price.groupID,
        tier: price.tier,
        product: price.product,
        status: price.status,
        currency: price.price.currency,
        createdAt: price.createdAt,
        updatedAt: price.updatedAt,
        ranges: [],
      };
      return {
        ...entries,
        [price.groupID]: {
          ...getEntry,
          ranges: [
            ...getEntry.ranges,
            {
              remark: price.description,
              moisture: price.moisture,
              price: price.price.amount,
            },
          ],
        },
      };
    }, {} as MoistureProductPrices);
    yield put(actions.createProductPriceSuccess(pricesData));
    yield put(
      addAlert({
        message: 'ເພີ່ມລາຄາສິນຄ້າສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createProductPriceFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* rejectProductPrice({
  payload,
}: ReturnType<typeof actions.rejectProductPriceRequest>) {
  try {
    yield call(API.post, '/v1/tiers/reject-moisture-purchase-prices', {
      groupID: payload.id,
      description: payload.description || '',
    });
    yield put(actions.rejectProductPriceSuccess(payload));
    yield put(
      addAlert({
        message: 'ປະຕິເສດລາຄາສິນຄ້າສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.rejectProductPriceFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}

function* watchGetProductPricesToday() {
  yield takeLatest(
    actions.Types.getProductPricesToDayRequest,
    getProductPricesToday
  );
}
function* watchGetProductPrices() {
  yield takeLatest(actions.Types.getProductPricesRequest, getProductPrices);
}
function* watchProductPricesOnChangePage() {
  yield takeLatest(
    actions.Types.productPricesOnChangePage,
    productPricesOnChangePage
  );
}
function* watchProductPricesOnChangePageSize() {
  yield takeLatest(
    actions.Types.productPricesOnChangePageSize,
    productPricesOnChangePageSize
  );
}
function* watchCreateProductPrice() {
  yield takeLatest(actions.Types.createProductPriceRequest, createProductPrice);
}
function* watchRejectProductPrice() {
  yield takeLatest(actions.Types.rejectProductPriceRequest, rejectProductPrice);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetProductPricesToday),
    fork(watchGetProductPrices),
    fork(watchProductPricesOnChangePage),
    fork(watchProductPricesOnChangePageSize),
    fork(watchCreateProductPrice),
    fork(watchRejectProductPrice),
  ]);
}
export default saga;
