import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import API from 'services/defaultInstance';
import * as actions from './index';
import { findErrorToData } from 'utils';
import { getAllDataWithCursorNextPage } from 'utils/index';
import type { Purchase } from 'models/purchase.v2';
import type { SaleTransaction } from 'models/sale';

function* listPurchases({
  payload,
}: ReturnType<typeof actions.listPurchaseRequest>) {
  const productName = payload.product;
  if (!productName) {
    yield put(
      actions.listPurchaseFailure({
        product: productName || '',
      })
    );
    return;
  }
  try {
    const data: Purchase[] = yield getAllDataWithCursorNextPage<
      Purchase[],
      'transactions'
    >({
      httpRequest: (queries) =>
        API.get(`/v1/purchase/transactions${queries}`, {
          params: {
            product: productName,
            tier: payload.tier,
            createdAt: payload.startedAt,
            orderBy: 'ASC',
          },
        }),
      keyResponse: 'transactions',
    });
    yield put(
      actions.listPurchaseSuccess({
        product: productName,
        data: data,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(
      actions.listPurchaseFailure({
        product: productName,
      })
    );
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* createPurchase({
  payload,
}: ReturnType<typeof actions.createPurchaseRequest>) {
  const { type, entries, ids, status, comment } = payload;
  const mapIds = entries.filter((item) => {
    return ids.includes(item.id);
  });
  if (mapIds.length === 0) {
    yield put(
      addAlert({
        message: 'ກະລຸນາເລືອກລາຍການຮັບຊື້ສິນຄ້າ',
        serviceType: 'snackbar',
        type: 'error',
      })
    );
    yield put(actions.createPurchaseFailure());
    return;
  }
  try {
    yield call(
      API.post,
      `/v1/admin/purchase/audit/${
        type === 'auditor' ? 'auditor-check' : 'head-check'
      }`,
      {
        ids: ids,
        status: status,
        comment: comment || '',
      }
    );
    yield put(
      actions.createPurchaseSuccess({
        product: mapIds[0].product.name,
        ids: mapIds,
        type,
        status,
        comment: comment || '',
      })
    );
    yield put(
      addAlert({
        message: 'ສໍາເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createPurchaseFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listSales({ payload }: ReturnType<typeof actions.listSaleRequest>) {
  const productName = payload.product;
  if (!productName) {
    yield put(
      actions.listSaleFailure({
        product: productName || '',
      })
    );
    return;
  }
  try {
    const data: SaleTransaction[] = yield getAllDataWithCursorNextPage<
      SaleTransaction[],
      'transactions'
    >({
      httpRequest: (queries) =>
        API.get(`/v1/sale/transactions${queries}`, {
          params: {
            product: productName,
            tier: payload.tier,
            createdAt: payload.startedAt,
            orderBy: 'ASC',
          },
        }),
      keyResponse: 'transactions',
    });
    yield put(
      actions.listSaleSuccess({
        product: productName,
        data: data,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(
      actions.listSaleFailure({
        product: productName,
      })
    );
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* createSale({
  payload,
}: ReturnType<typeof actions.createSaleRequest>) {
  const { type, entries, ids, status, comment } = payload;
  const mapIds = entries.filter((item) => {
    return ids.includes(item.id);
  });
  if (mapIds.length === 0) {
    yield put(
      addAlert({
        message: 'ກະລຸນາເລືອກລາຍການຮັບຊື້ສິນຄ້າ',
        serviceType: 'snackbar',
        type: 'error',
      })
    );
    yield put(actions.createSaleFailure());
    return;
  }
  try {
    yield call(
      API.post,
      `/v1/admin/sale/audit/${
        type === 'auditor' ? 'auditor-check' : 'head-check'
      }`,
      {
        ids: ids,
        status: status,
        comment: comment || '',
      }
    );
    yield put(
      actions.createSaleSuccess({
        product: mapIds[0].product.name,
        ids: mapIds,
        type: type,
        status: status,
        comment: comment || '',
      })
    );
    yield put(
      addAlert({
        message: 'ສໍາເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createSaleFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchListPurchase() {
  yield takeLatest(actions.Types.listPurchaseRequest, listPurchases);
}
function* watchCreatePurchase() {
  yield takeLatest(actions.Types.createPurchaseRequest, createPurchase);
}
function* watchListSales() {
  yield takeLatest(actions.Types.listSaleRequest, listSales);
}
function* watchCreateSale() {
  yield takeLatest(actions.Types.createSaleRequest, createSale);
}
function* saga() {
  yield all([
    fork(watchListPurchase),
    fork(watchCreatePurchase),
    fork(watchListSales),
    fork(watchCreateSale),
  ]);
}
export default saga;
