import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { addAlert } from 'store/notify';
import { Canceler, CancelToken } from 'services/defaultInstance';
import moment from 'moment';
import {
  listCategoriesAPI,
  createCategoryAPI,
  updateCategoryAPI,
  listProductsAPI,
  getProductsAPI,
  updateProductSizesAPI,
  updateProductGradesAPI,
  createProductsAPI,
  updateProductsAPI,
  listProcessAPI,
  updateProcessAPI,
  listLossMoistureAPI,
  createLossMoistureAPI,
  lisLossEstimateAPI,
  updateLossEstimateAPI,
  deleteLossEstimateAPI,
  deleteProcessAPI,
} from 'services/product';
import * as actions from './index';
import { findErrorToData } from 'utils';
import type { Category } from 'models/product';
import type {
  Product,
  ProductProcess,
  ProductProcessField,
  Moisture,
  Estimate,
  EstimateForm,
} from 'models/product.v2';
let cancels: Canceler[] = [];
function* getProducts({
  payload,
}: ReturnType<typeof actions.getProductsRequest>) {
  const { page, isFetchNextPage, ...params } = payload;
  const { listProduct }: actions.InitialState = yield select(
    (state) => state.superAdmin.products
  );
  try {
    const cancelToken = new CancelToken((c) => cancels.push(c));
    const res: ListRequestDataRes<'products', Product[]> = yield call(
      listProductsAPI,
      { pageSize: listProduct.pageSize, ...params },
      cancelToken
    );
    cancels = [];
    let newData: SetListData<Product[]> = {
      data: res.products,
      page: page || listProduct.page,
      pageSize: params.pageSize || listProduct.pageSize,
    };
    if (isFetchNextPage) {
      newData.pageTokens = [...listProduct.pageTokens, res.nextPageToken];
    }
    yield put(actions.getProductsSuccess(newData));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getProductsFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* getProduct({
  payload,
}: ReturnType<typeof actions.getProductRequest>) {
  if (typeof payload !== 'string') {
    yield put(actions.getProductFailure());
    return;
  }
  const { listProduct }: actions.InitialState = yield select(
    (state) => state.superAdmin.products
  );
  const product = listProduct.data.find((p) => p.id === payload);
  if (product) {
    yield put(actions.getProductSuccess(product));
    return;
  }
  try {
    const res: Product = yield call(getProductsAPI, payload);
    yield put(actions.getProductSuccess(res));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getProductFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listCategories({
  payload,
}: ReturnType<typeof actions.listCategoriesRequest>) {
  const { page, isFetchNextPage, ...params } = payload;
  const { listCategory }: actions.InitialState = yield select(
    (state) => state.superAdmin.products
  );
  try {
    const cancelToken = new CancelToken((c) => cancels.push(c));
    const res: ListRequestDataRes<'products', Category[]> = yield call(
      listCategoriesAPI,
      { pageSize: listCategory.pageSize, ...params },
      cancelToken
    );
    cancels = [];
    const categories = res.products.filter((item) => !item.parentCategory);
    const entries = categories.reduce((entries, entry) => {
      const subCategories = res.products.filter(
        (item) => item.parentCategory === entry.name
      );
      return [...entries, entry, ...subCategories];
    }, [] as Category[]);
    let newData: SetListData<Category[]> = {
      data: entries,
      page: page || listCategory.page,
      pageSize: params.pageSize || listCategory.pageSize,
    };
    if (isFetchNextPage) {
      newData.pageTokens = [...listCategory.pageTokens, res.nextPageToken];
    }
    yield put(actions.listCategoriesSuccess(newData));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listCategoriesFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* createCategory({
  payload,
}: ReturnType<typeof actions.createCategoryRequest>) {
  try {
    yield call(createCategoryAPI, payload);

    yield put(actions.createCategorySuccess());
    yield put(actions.listCategoriesRequest({}));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createCategoryFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateCategory({
  payload,
}: ReturnType<typeof actions.updateCategoryRequest>) {
  const { id, ...data } = payload;
  try {
    yield call(updateCategoryAPI, data.name, data);

    yield put(actions.updateCategorySuccess());
    yield put(actions.listCategoriesRequest({}));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateCategoryFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* productPageSizes({
  payload,
}: ReturnType<typeof actions.updateProductSizesRequest>) {
  const { productName, sizes } = payload;
  const sizesNumbers = sizes.map((s) => Number.parseInt(s.value));
  const data = Array.from(new Set(sizesNumbers));
  try {
    yield call(updateProductSizesAPI, productName, data);
    yield put(actions.updateProductSizesSuccess(data));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateProductSizesFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* productPageGrades({
  payload,
}: ReturnType<typeof actions.updateProductGradesRequest>) {
  const { productName, gradesTitle } = payload;
  const gradesNumbers = gradesTitle.map((s) => s.value);
  const data = Array.from(new Set(gradesNumbers));
  try {
    yield call(updateProductGradesAPI, productName, data);
    yield put(actions.updateProductGradesSuccess(data));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateProductGradesFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* createProduct({
  payload,
}: ReturnType<typeof actions.createProductRequest>) {
  try {
    const subCategory =
      (payload.subCategory || '').length === 0 ? null : payload.subCategory;
    yield call(createProductsAPI, {
      ...payload,
      subCategory: subCategory,
    });
    yield put(actions.createProductSuccess());
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createProductFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateProduct({
  payload,
}: ReturnType<typeof actions.updateProductRequest>) {
  const { id, ...data } = payload;
  try {
    const subCategory =
      (data.subCategory || '').length === 0 ? null : data.subCategory;
    yield call(updateProductsAPI, id, {
      ...data,
      subCategory: subCategory,
    });
    yield put(actions.updateProductSuccess());
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateProductFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listProcess({
  payload,
}: ReturnType<typeof actions.listProcessRequest>) {
  if (typeof payload !== 'string') {
    yield put(actions.listProcessFailure());
    return;
  }
  try {
    const res: ProductProcess[] = yield call(listProcessAPI, payload);
    res.sort((a, b) => {
      if (moment(a.createdAt).isBefore(moment(b.createdAt))) return -1;
      if (moment(a.createdAt).isAfter(moment(b.createdAt))) return 1;
      return 0;
    });
    yield put(actions.listProcessSuccess(res));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listProcessFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateProcess({
  payload,
}: ReturnType<typeof actions.updateProcessRequest>) {
  const { process }: actions.InitialState = yield select(
    (state) => state.superAdmin.products
  );
  let data: ProductProcessField[] = process.data
    .reduce((entries, entry) => {
      const similarEntry = entries.find(
        (item) => item.processToProduct === entry.processToProduct
      );
      if (similarEntry) return entries;
      return [...entries, entry];
    }, [] as ProductProcess[])
    .map((item) => {
      if (item.id === payload.id) {
        return {
          formula: payload.formula,
          processToProduct: payload.processToProduct,
          productName: payload.productName,
          remark: payload.remark,
        };
      }
      return {
        formula: item.formula,
        processToProduct: item.processToProduct,
        productName: item.productName,
        remark: item.remark,
      };
    });
  if (payload && (payload.id || '').length === 0) {
    data.push({
      formula: payload.formula,
      processToProduct: payload.processToProduct,
      productName: payload.productName,
      remark: payload.remark,
    });
  }
  const entries = data.reduce((entries, entry) => {
    const similarEntry = entries.find(
      (item) => item.processToProduct === entry.processToProduct
    );
    if (similarEntry) return entries;
    return [...entries, entry];
  }, [] as ProductProcessField[]);
  try {
    yield call(updateProcessAPI, entries);
    yield put(actions.updateProcessSuccess());
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateProcessFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* deleteProcess({
  payload,
}: ReturnType<typeof actions.deleteProcessRequest>) {
  try {
    yield call(deleteProcessAPI, payload.id);
    yield put(actions.updateProcessSuccess());
    yield put(actions.deleteProcessSuccess(payload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.deleteProcessFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listLossMoisture({
  payload,
}: ReturnType<typeof actions.listLossMoistureRequest>) {
  if (typeof payload !== 'string') {
    yield put(actions.listProcessFailure());
    return;
  }
  try {
    const res: Moisture[] = yield call(listLossMoistureAPI, payload);
    yield put(actions.listLossMoistureSuccess(res));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listLossMoistureFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* createLossMoisture({
  payload,
}: ReturnType<typeof actions.createLossMoistureRequest>) {
  const { getProduct }: actions.InitialState = yield select(
    (state) => state.superAdmin.products
  );
  try {
    yield call(
      createLossMoistureAPI,
      getProduct.data?.name || '',
      payload.map((p) => ({
        productName: p.productName,
        currentMoisture: Number.parseFloat(`${p.currentMoisture}`),
        targetMoisture: Number.parseFloat(`${p.targetMoisture}`),
        moisture: Number.parseFloat(`${p.moisture}`),
        lossVolume: Number.parseFloat(`${p.lossVolume}`),
      }))
      // .reverse()
    );
    yield put(actions.createLossMoistureSuccess(payload));
    yield put(
      addAlert({
        message: 'ເພີ່ມລາຍການສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createLossMoistureFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* listLossEstimate({
  payload,
}: ReturnType<typeof actions.listLossEstimateRequest>) {
  if (typeof payload !== 'string') {
    yield put(actions.listLossEstimateFailure());
    return;
  }
  try {
    const res: Estimate[] = yield call(lisLossEstimateAPI, payload);
    yield put(actions.listLossEstimateSuccess(res));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.listLossEstimateFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateLossEstimate({
  payload,
}: ReturnType<typeof actions.createLossEstimateRequest>) {
  const { estimate }: actions.InitialState = yield select(
    (state) => state.superAdmin.products
  );

  let data: EstimateForm[] = estimate.data.map((item) => {
    if (item.id === payload.id) {
      return {
        lossPercent: payload.lossPercent,
        lossType: payload.lossType,
        productName: payload.productName,
      };
    }
    return {
      lossPercent: item.lossPercent,
      lossType: item.lossType,
      productName: item.productName,
    };
  });
  if ((payload.id || '')?.length === 0) {
    data.push({
      lossPercent: payload.lossPercent,
      lossType: payload.lossType,
      productName: payload.productName,
    });
  }
  try {
    yield call(updateLossEstimateAPI, data);
    yield put(actions.createLossEstimateSuccess());
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createLossEstimateFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* deleteLossEstimate({
  payload,
}: ReturnType<typeof actions.deleteLossEstimateRequest>) {
  const { id, ...data } = payload;
  try {
    yield call(deleteLossEstimateAPI, id || '');
    yield put(actions.deleteLossEstimateSuccess({ ...data, id }));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.deleteLossEstimateFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetProducts() {
  yield takeLatest(actions.Types.getProductsRequest, getProducts);
}
function* watchGetProduct() {
  yield takeLatest(actions.Types.getProductRequest, getProduct);
}
function* watchListCategories() {
  yield takeLatest(actions.Types.listCategoriesRequest, listCategories);
}
function* watchCreateCategory() {
  yield takeLatest(actions.Types.createCategoryRequest, createCategory);
}
function* watchUpdateCategory() {
  yield takeLatest(actions.Types.updateCategoryRequest, updateCategory);
}
function* watchProductSizes() {
  yield takeLatest(actions.Types.updateProductSizesRequest, productPageSizes);
}
function* watchProductGrades() {
  yield takeLatest(actions.Types.updateProductGradesRequest, productPageGrades);
}
function* watchCreateProduct() {
  yield takeLatest(actions.Types.createProductRequest, createProduct);
}
function* watchUpdateProduct() {
  yield takeLatest(actions.Types.updateProductRequest, updateProduct);
}
function* watchListProcess() {
  yield takeLatest(actions.Types.listProcessRequest, listProcess);
}
function* watchUpdateProcess() {
  yield takeLatest(actions.Types.updateProcessRequest, updateProcess);
}
function* watchDeleteProcess() {
  yield takeLatest(actions.Types.deleteProcessRequest, deleteProcess);
}
function* watchListLossMoisture() {
  yield takeLatest(actions.Types.listLossMoistureRequest, listLossMoisture);
}
function* watchCreateLossMoisture() {
  yield takeLatest(actions.Types.createLossMoistureRequest, createLossMoisture);
}
function* watchListLossEstimate() {
  yield takeLatest(actions.Types.listLossEstimateRequest, listLossEstimate);
}
function* watchUpdateLossEstimate() {
  yield takeLatest(actions.Types.createLossEstimateRequest, updateLossEstimate);
}
function* watchDeleteLossEstimate() {
  yield takeLatest(actions.Types.deleteLossEstimateRequest, deleteLossEstimate);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetProducts),
    fork(watchGetProduct),
    fork(watchListCategories),
    fork(watchCreateCategory),
    fork(watchUpdateCategory),
    fork(watchProductSizes),
    fork(watchProductGrades),
    fork(watchCreateProduct),
    fork(watchUpdateProduct),
    fork(watchListProcess),
    fork(watchUpdateProcess),
    fork(watchDeleteProcess),
    fork(watchListLossMoisture),
    fork(watchCreateLossMoisture),
    fork(watchListLossEstimate),
    fork(watchUpdateLossEstimate),
    fork(watchDeleteLossEstimate),
  ]);
}
export default saga;
