import {
  all,
  fork,
  put,
  call,
  takeLatest,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { addAlert } from 'store/notify';
import API, { Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  CreateUser,
  GetRolesResponse,
  GetUsersResponse,
  GetZonesResponse,
} from 'models';
import { findErrorToData } from 'utils';
import { batchListUserSuccess } from 'store/db';
import { RootState } from 'store/index';

let cancels: Canceler[] = [];

function* getUsers({ payload }: ReturnType<typeof actions.getUsersRequest>) {
  const { list }: actions.InitialState = yield select(
    (state) => state.superAdmin.users
  );
  let query = `pageSize=${payload.pageSize ? payload.pageSize : list.pageSize}`;
  if (payload.pageToken) {
    query += `&pageToken=${payload.pageToken}`;
  }
  if (payload.username) {
    query += `&username=${encodeURIComponent(payload.username)}`;
  }
  if (payload.firstName) {
    query += `&firstName=${encodeURIComponent(payload.firstName)}`;
  }
  let dataPayload: actions.GetUsersSuccess = {
    users: [],
    pageTokens: [...list.pageTokens],
  };
  try {
    const { data }: GetUsersResponse = yield call(
      API.get,
      `/v1/admin/tier/users?${query}`
    );
    dataPayload.users = data.users;
    const pageToken = list.pageTokens.find((p) => p === data.nextPageToken);
    if (
      !pageToken &&
      dataPayload.pageTokens &&
      data.nextPageToken.length !== 0
    ) {
      dataPayload.pageTokens.push(data.nextPageToken);
    }
    if (payload.page) {
      dataPayload.page = payload.page;
    }
    if (payload.pageSize && payload.pageSize !== list.pageSize) {
      dataPayload.pageTokens =
        data.nextPageToken.length === 0 ? [] : [data.nextPageToken];
      dataPayload.pageSize = payload.pageSize;
      dataPayload.page = 1;
    }
    yield put(actions.getUsersSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getUsersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* onChangeUsersPage({
  payload,
}: ReturnType<typeof actions.onChangeUsersPage>) {
  const {
    list: { pageTokens, page },
  }: actions.InitialState = yield select((state) => state.superAdmin.users);
  let cloneFilters: any = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getUsersRequest(cloneFilters));
}
function* createUser({
  payload,
}: ReturnType<typeof actions.createUsersRequest>) {
  const user: CreateUser = {
    email: payload.email,
    password: payload.password,
    firstName: payload.firstName,
    lastName: payload.lastName,
    phoneNumber: payload.phoneNumber,
    username: payload.username,
    role: payload.role,
    zone: payload.zone,
  };
  try {
    yield call(API.post, '/v1/admin/tier/users', user);
    yield put(actions.createUsersSuccess());
    yield put(
      addAlert({
        message: 'ສ້າງຜູ້ໃຊ້ສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(
      batchListUserSuccess({
        users: [],
        nextPageToken: '',
      })
    );
    yield put(push({ pathname: '/super-admin/users' }));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createUsersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* openPageUserCreate() {
  const {
    auth: {
      accept: { resourceAccess },
    },
  }: RootState = yield select((state) => state);
  let roles: any = [];
  let zones: any = [];
  try {
    if (resourceAccess['admin-roles'].read) {
      const RolesReq: GetRolesResponse = yield call(
        API.get,
        '/v1/admin/tier/roles?pageSize=250'
      );
      roles = RolesReq.data.roles;
    }
    if (resourceAccess['admin-zones'].read) {
      const zonesReq: GetZonesResponse = yield call(
        API.get,
        '/v1/admin/tier/zones?pageSize=250'
      );
      zones = zonesReq.data.zones;
    }
    yield put(
      actions.openPageCreateUserSuccess({
        roles: roles,
        zones: zones,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.openPageCreateUserFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}

function* getUser({ payload }: ReturnType<typeof actions.getUserRequest>) {
  const {
    auth: {
      accept: { resourceAccess },
    },
  }: RootState = yield select((state) => state);
  let roles: any = [];
  let zones: any = [];
  try {
    if (resourceAccess['admin-roles'].read) {
      const RolesReq: GetRolesResponse = yield call(
        API.get,
        '/v1/admin/tier/roles?pageSize=250'
      );
      roles = RolesReq.data.roles;
    }
    if (resourceAccess['admin-zones'].read) {
      const zonesReq: GetZonesResponse = yield call(
        API.get,
        '/v1/admin/tier/zones?pageSize=250'
      );
      zones = zonesReq.data.zones;
    }
    const { data } = yield call(API.get, `/v1/admin/tier/users/${payload}`);
    yield put(
      actions.getUserSuccess({
        roles: roles,
        user: data.username,
        zones: zones,
      })
    );
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getUserFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updateUser({
  payload,
}: ReturnType<typeof actions.updateUsersRequest>) {
  try {
    yield call(API.post, `/v1/admin/tier/users-update`, {
      username: payload.username,
      firstName: payload.firstName,
      lastName: payload.lastName,
      phoneNumber: payload.phoneNumber,
      role: payload.role,
      zone: payload.zone,
      email: payload.email,
    });
    yield put(actions.updateUsersSuccess());
    yield put(
      addAlert({
        message: 'ແກ້ໄຂຜູ້ໃຊ້ສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(
      batchListUserSuccess({
        users: [],
        nextPageToken: '',
      })
    );
    yield put(push({ pathname: '/super-admin/users' }));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updateUsersFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* updatePassword({
  payload,
}: ReturnType<typeof actions.updatePasswordRequest>) {
  const {
    update: { user },
  }: actions.InitialState = yield select((state) => state.superAdmin.users);
  try {
    yield call(API.post, `/v1/admin/tier/users-changePassword`, {
      username: user?.username || '',
      password: payload.password,
    });
    yield put(actions.updatePasswordSuccess());
    yield put(
      addAlert({
        message: 'ອັບເດດລະຫັດຜ່ານສຳເລັດ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.updatePasswordFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetUsers() {
  yield takeLatest(actions.Types.getUsersRequest, getUsers);
}
function* watchOnChangeUsersPage() {
  yield takeLatest(actions.Types.onChangeUsersPage, onChangeUsersPage);
}
function* watchOnChangeUsersPageSize() {
  yield takeLatest(
    actions.Types.onChangeUsersPageSize,
    function* ({ payload }: ReturnType<typeof actions.onChangeUsersPageSize>) {
      yield put(actions.getUsersRequest(payload));
    }
  );
}
function* watchCreateUser() {
  yield takeLatest(actions.Types.createUsersRequest, createUser);
}
function* watchOpenPageUserCreate() {
  yield takeLatest(actions.Types.openPageCreateUserRequest, openPageUserCreate);
}
function* watchUpdateUser() {
  yield takeLatest(actions.Types.updateUsersRequest, updateUser);
}
function* watchGetUser() {
  yield takeLatest(actions.Types.getUserRequest, getUser);
}
function* watchUpdatePassword() {
  yield takeEvery(actions.Types.updatePasswordRequest, updatePassword);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetUsers),
    fork(watchOnChangeUsersPage),
    fork(watchOnChangeUsersPageSize),
    fork(watchCreateUser),
    fork(watchOpenPageUserCreate),
    fork(watchUpdateUser),
    fork(watchGetUser),
    fork(watchUpdatePassword),
  ]);
}
export default saga;
