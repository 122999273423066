import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type {
  Category,
  CategoriesParams,
  User,
  UsersFilter,
  Tier,
  TiersFilter,
  Customer,
  CustomersFilter,
  Lot,
} from 'models';
import type { Product, ProductsParams } from 'models/product.v2';

export interface InitialState {
  category: DBInitialState<Category[]>;
  product: DBInitialState<Product[]>;
  user: DBInitialState<User[]>;
  tier: DBInitialState<Tier[]>;
  customer: DBInitialState<Customer[]>;
  lots: DBInitialState<Lot[]>;
}
const initialState: InitialState = {
  category: {
    data: [],
    loading: false,
    nextPageToken: '',
  },
  product: {
    data: [],
    loading: false,
    nextPageToken: '',
  },
  user: {
    data: [],
    loading: false,
    nextPageToken: '',
  },
  tier: {
    data: [],
    loading: false,
    nextPageToken: '',
  },
  customer: {
    data: [],
    loading: false,
    nextPageToken: '',
  },
  lots: {
    data: [],
    loading: false,
    nextPageToken: '',
  },
};
export enum Types {
  listCategoriesRequest = 'db/listCategoriesRequest',
  listCategoriesSuccess = 'db/listCategoriesSuccess',
  listCategoriesFailure = 'db/listCategoriesFailure',
  listProductRequest = 'db/listProductRequest',
  listProductSuccess = 'db/listProductSuccess',
  listProductFailure = 'db/listProductFailure',
  batchListUserRequest = 'db/batchListUserRequest',
  batchListUserSuccess = 'db/batchListUserSuccess',
  batchListUserFailure = 'db/batchListUserFailure',
  batchListTierRequest = 'db/batchListTierRequest',
  batchListTierSuccess = 'db/batchListTierSuccess',
  batchListTierFailure = 'db/batchListTierFailure',
  listCustomerRequest = 'db/listCustomerRequest',
  listCustomerSuccess = 'db/listCustomerSuccess',
  listCustomerFailure = 'db/listCustomerFailure',
  listLotsRequest = 'db/listLotsRequest',
  listLotsSuccess = 'db/listLotsSuccess',
  listLotsFailure = 'db/listLotsFailure',
}
const slice = createSlice({
  name: 'db',
  initialState,
  reducers: {
    listCategoriesRequest(state, _: PayloadAction<CategoriesParams>) {
      state.category.loading = true;
    },
    listCategoriesSuccess(
      state,
      { payload }: PayloadAction<ListRequestDataRes<'products', Category[]>>
    ) {
      state.category.loading = false;
      state.category.data = [...state.category.data, ...payload.products];
      state.category.nextPageToken = payload.nextPageToken;
    },
    listCategoriesFailure(state) {
      state.category.loading = false;
    },
    listProductRequest(state, _: PayloadAction<ProductsParams>) {
      state.product.loading = true;
    },
    listProductSuccess(
      state,
      { payload }: PayloadAction<ListRequestDataRes<'products', Product[]>>
    ) {
      state.product.loading = false;
      state.product.data = [...state.product.data, ...payload.products];
      state.product.nextPageToken = payload.nextPageToken;
    },
    listProductFailure(state) {
      state.product.loading = false;
    },
    batchListUserRequest(state, _: PayloadAction<UsersFilter>) {
      state.user.loading = true;
    },
    batchListUserSuccess(
      state,
      { payload }: PayloadAction<ListRequestDataRes<'users', User[]>>
    ) {
      state.user.loading = false;
      state.user.data = payload.users;
      state.user.nextPageToken = payload.nextPageToken;
    },
    batchListUserFailure(state) {
      state.user.loading = false;
    },
    batchListTierRequest(state, _: PayloadAction<TiersFilter>) {
      state.tier.loading = true;
    },
    batchListTierSuccess(
      state,
      { payload }: PayloadAction<ListRequestDataRes<'tiers', Tier[]>>
    ) {
      state.tier.loading = false;
      state.tier.data = payload.tiers;
      state.tier.nextPageToken = payload.nextPageToken;
    },
    batchListTierFailure(state) {
      state.tier.loading = false;
    },
    listCustomerRequest(state, _: PayloadAction<CustomersFilter>) {
      state.customer.loading = true;
    },
    listCustomerSuccess(
      state,
      { payload }: PayloadAction<ListRequestDataRes<'customers', Customer[]>>
    ) {
      state.customer.loading = false;
      state.customer.data = payload.customers;
      state.customer.nextPageToken = payload.nextPageToken;
    },
    listCustomerFailure(state) {
      state.customer.loading = false;
    },
    listLotsRequest(state, _: PayloadAction<ProductsParams>) {
      state.lots.loading = true;
    },
    listLotsSuccess(
      state,
      { payload }: PayloadAction<ListRequestDataRes<'lots', Lot[]>>
    ) {
      state.lots.loading = false;
      state.lots.data = payload.lots;
      state.lots.nextPageToken = payload.nextPageToken;
    },
    listLotsFailure(state) {
      state.lots.loading = false;
    },
  },
});
export const {
  listCategoriesFailure,
  listCategoriesRequest,
  listCategoriesSuccess,
  listProductFailure,
  listProductRequest,
  listProductSuccess,
  batchListUserRequest,
  batchListUserSuccess,
  batchListUserFailure,
  batchListTierRequest,
  batchListTierSuccess,
  batchListTierFailure,
  listCustomerFailure,
  listCustomerRequest,
  listCustomerSuccess,
  listLotsRequest,
  listLotsFailure,
  listLotsSuccess,
} = slice.actions;
export default slice.reducer;
