import { lazy } from 'react';

export const MoistureProductPrice = lazy(() => import('./product-prices'));
export const ProductPricePendingAndApprove = lazy(
  () => import('./product-prices/PendingAndApprove')
);
export const ProductPriceUnApproveAndReject = lazy(
  () => import('./product-prices/UnApproveAndReject')
);
export const Wallet = lazy(() => import('./Wallet'));
export const Purchases = lazy(() => import('./purchases/Purchases'));
export const Audit = lazy(() => import('./purchases/Audit'));
export const AuditSale = lazy(() => import('./sale/Audit'));
export const Purchase = lazy(() => import('./purchases/Purchase'));
export const PurchasesSummaries = lazy(() => import('./purchases/Summaries'));
export const PurchaseSummary = lazy(() => import('./purchases/Summary'));
export const Menu = lazy(() => import('./Menu'));
export const QualityCheck = lazy(() => import('./QualityCheck'));
export const QualitycheckCreate = lazy(() => import('./QualitycheckCreate'));
export const InventoryStocks = lazy(() => import('./inventory/Stocks'));
export const InventoryLots = lazy(() => import('./inventory/Lots'));
export const InventoryLot = lazy(() => import('./inventory/Lot'));
export const Stock = lazy(() => import('./inventory/Stock'));
export const StockDetail = lazy(() => import('./inventory/StockDetail'));
export const StockProcess = lazy(() => import('./inventory/StockProcess'));
export const SaleOrders = lazy(() => import('./sale/Orders'));
export const SaleOrder = lazy(() => import('./sale/Order'));
export const OrderUpdate = lazy(() => import('./sale/OrderUpdate'));
export const SaleTransactions = lazy(() => import('./sale/Transactions'));
export const SaleTransaction = lazy(() => import('./sale/Transaction'));
export const SaleTransactionCreate = lazy(
  () => import('./sale/TransactionCreate')
);
export const SaleInvoices = lazy(() => import('./sale/Invoices'));
export const SaleInvoice = lazy(() => import('./sale/Invoice'));
export const SaleInvoiceCreate = lazy(() => import('./sale/InvoiceCreate'));
export const SaleInvoiceUpdate = lazy(() => import('./sale/InvoiceUpdate'));
export const SaleTransfers = lazy(() => import('./sale/Transfers'));
export const SaleReceipts = lazy(() => import('./sale/Receipts'));
export const SaleReceipt = lazy(() => import('./sale/Receipt'));
export const SaleReceiptCreate = lazy(() => import('./sale/ReceiptCreate'));
export const CreateOrder = lazy(() => import('./sale/CreateOrder'));
export const Customers = lazy(() => import('./customers/Customers'));
export const CustomerCreate = lazy(() => import('./customers/CustomerCreate'));
export const CustomerUpdate = lazy(() => import('./customers/CustomerUpdate'));
export const RivalPurchasePrices = lazy(
  () => import('./rival-purchase-prices/RivalPurchasePrices')
);
export const Expenses = lazy(() => import('./expenses/Expenses'));
