import { createReducer, createAction } from '@reduxjs/toolkit';
import type { Category, CategoriesParams, CategoryField } from 'models';
import type {
  Product,
  ProductsParams,
  ProductField,
  ProductSizesField,
  ProductGradesField,
  ProductProcess,
  ProductProcessField,
  Moisture,
  MoistureField,
  Estimate,
  EstimateField,
} from 'models/product.v2';
import moment from 'moment';
export interface InitialState {
  listProduct: InitialStateList<Product>;
  createProduct: InitialStateCreate;
  updateProduct: InitialStateUpdate;
  listCategory: InitialStateList<Category>;
  createCategory: InitialStateCreate;
  updateCategory: InitialStateUpdate;
  getProduct: InitialStateUpdate<Product> & {
    loading: boolean;
  };
  process: InitialStateList<ProductProcess> & InitialStateCreate;
  lossMoisture: InitialStateList<Moisture> & InitialStateCreate;
  estimate: InitialStateList<Estimate> & InitialStateCreate;
}
const initialState: InitialState = {
  listProduct: {
    isLoading: false,
    data: [],
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  listCategory: {
    isLoading: false,
    data: [],
    page: 1,
    pageSize: 50,
    pageTokens: [],
  },
  createCategory: {
    isPending: false,
    isSubmitted: false,
  },
  updateCategory: {
    data: null,
    isPending: false,
    isSubmitted: false,
  },
  createProduct: {
    isPending: false,
    isSubmitted: false,
  },
  updateProduct: {
    data: null,
    isPending: false,
    isSubmitted: false,
  },
  getProduct: {
    data: null,
    isPending: false,
    isSubmitted: false,
    loading: false,
  },
  process: {
    data: [],
    isPending: false,
    isSubmitted: false,
    isLoading: false,
    page: 1,
    pageSize: 250,
    pageTokens: [],
  },
  lossMoisture: {
    data: [],
    isLoading: false,
    isPending: false,
    isSubmitted: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
  estimate: {
    data: [],
    isLoading: false,
    isPending: false,
    isSubmitted: false,
    page: 1,
    pageSize: 25,
    pageTokens: [],
  },
};
export enum Types {
  cancelRequestAPI = 'super-admin/products@cancelRequestAPI',
  getProductsRequest = 'super-admin/products@getProductsRequest',
  getProductsSuccess = 'super-admin/products@getProductsSuccess',
  getProductsFailure = 'super-admin/products@getProductsFailure',
  getProductRequest = 'super-admin/products@getProductRequest',
  getProductSuccess = 'super-admin/products@getProductSuccess',
  getProductFailure = 'super-admin/products@getProductFailure',
  createProductRequest = 'super-admin/products@createProductRequest',
  createProductSuccess = 'super-admin/products@createProductSuccess',
  createProductFailure = 'super-admin/products@createProductFailure',
  updateProductRequest = 'super-admin/products@updateProductRequest',
  updateProductSuccess = 'super-admin/products@updateProductSuccess',
  updateProductFailure = 'super-admin/products@updateProductFailure',
  listCategoriesRequest = 'super-admin/products@listCategoriesRequest',
  listCategoriesSuccess = 'super-admin/products@listCategoriesSuccess',
  listCategoriesFailure = 'super-admin/products@listCategoriesFailure',
  categoriesOnChangePage = 'categoriesOnChangePage',
  categoriesOnChangePageSize = 'categoriesOnChangePageSize',
  createCategoryRequest = 'super-admin/products@createCategoryRequest',
  createCategorySuccess = 'super-admin/products@createCategorySuccess',
  createCategoryFailure = 'super-admin/products@createCategoryFailure',
  updateCategoryRequest = 'super-admin/products@updateCategoryRequest',
  updateCategorySuccess = 'super-admin/products@updateCategorySuccess',
  updateCategoryFailure = 'super-admin/products@updateCategoryFailure',
  afterSubmitted = 'super-admin/products@afterSubmitted',
  updateProductSizesRequest = 'super-admin/products@updateProductSizesRequest',
  updateProductSizesSuccess = 'super-admin/products@updateProductSizesSuccess',
  updateProductSizesFailure = 'super-admin/products@updateProductSizesFailure',
  updateProductGradesRequest = 'super-admin/products@updateProductGradesRequest',
  updateProductGradesSuccess = 'super-admin/products@updateProductGradesSuccess',
  updateProductGradesFailure = 'super-admin/products@updateProductGradesFailure',
  listProcessRequest = 'super-admin/products@listProcessRequest',
  listProcessSuccess = 'super-admin/products@listProcessSuccess',
  listProcessFailure = 'super-admin/products@listProcessFailure',
  updateProcessRequest = 'super-admin/products@updateProcessRequest',
  updateProcessSuccess = 'super-admin/products@updateProcessSuccess',
  updateProcessFailure = 'super-admin/products@updateProcessFailure',
  deleteProcessRequest = 'super-admin/products@deleteProcessRequest',
  deleteProcessSuccess = 'super-admin/products@deleteProcessSuccess',
  deleteProcessFailure = 'super-admin/products@deleteProcessFailure',
  listLossMoistureRequest = 'super-admin/products@listLossMoistureRequest',
  listLossMoistureSuccess = 'super-admin/products@listLossMoistureSuccess',
  listLossMoistureFailure = 'super-admin/products@listLossMoistureFailure',
  createLossMoistureRequest = 'super-admin/products@createLossMoistureRequest',
  createLossMoistureSuccess = 'super-admin/products@createLossMoistureSuccess',
  createLossMoistureFailure = 'super-admin/products@createLossMoistureFailure',
  listLossEstimateRequest = 'super-admin/products@listLossEstimateRequest',
  listLossEstimateSuccess = 'super-admin/products@listLossEstimateSuccess',
  listLossEstimateFailure = 'super-admin/products@listLossEstimateFailure',
  createLossEstimateRequest = 'super-admin/products@createLossEstimateRequest',
  createLossEstimateSuccess = 'super-admin/products@createLossEstimateSuccess',
  createLossEstimateFailure = 'super-admin/products@createLossEstimateFailure',
  deleteLossEstimateRequest = 'super-admin/products@deleteLossEstimateRequest',
  deleteLossEstimateSuccess = 'super-admin/products@deleteLossEstimateSuccess',
  deleteLossEstimateFailure = 'super-admin/products@deleteLossEstimateFailure',
}
export const cancelRequestAPI = createAction(Types.cancelRequestAPI);
export const getProductsRequest = createAction<ProductsParams>(
  Types.getProductsRequest
);
export const getProductsSuccess = createAction<SetListData<Product[]>>(
  Types.getProductsSuccess
);
export const getProductsFailure = createAction<string>(
  Types.getProductsFailure
);
export const getProductRequest = createAction<string>(Types.getProductRequest);
export const getProductSuccess = createAction<Product>(Types.getProductSuccess);
export const getProductFailure = createAction(Types.getProductFailure);
export const listCategoriesRequest = createAction<CategoriesParams>(
  Types.listCategoriesRequest
);
export const listCategoriesSuccess = createAction<SetListData<Category[]>>(
  Types.listCategoriesSuccess
);
export const listCategoriesFailure = createAction(Types.listCategoriesFailure);
export const categoriesOnChangePage = createAction<CategoriesParams>(
  Types.categoriesOnChangePage
);
export const categoriesOnChangePageSize = createAction<CategoriesParams>(
  Types.categoriesOnChangePageSize
);
export const createCategoryRequest = createAction<CategoryField>(
  Types.createCategoryRequest
);
export const createCategorySuccess = createAction(Types.createCategorySuccess);
export const createCategoryFailure = createAction(Types.createCategoryFailure);
export const updateCategoryRequest = createAction<
  CategoryField & { id: string }
>(Types.updateCategoryRequest);
export const updateCategorySuccess = createAction(Types.updateCategorySuccess);
export const updateCategoryFailure = createAction(Types.updateCategoryFailure);
export const afterSubmitted = createAction(Types.afterSubmitted);
export const createProductRequest = createAction<ProductField>(
  Types.createProductRequest
);
export const createProductSuccess = createAction(Types.createProductSuccess);
export const createProductFailure = createAction(Types.createProductFailure);
export const updateProductRequest = createAction<
  ProductField & {
    id: string;
  }
>(Types.updateProductRequest);
export const updateProductSuccess = createAction(Types.updateProductSuccess);
export const updateProductFailure = createAction(Types.updateProductFailure);
export const updateProductSizesRequest = createAction<
  ProductSizesField & { productName: string }
>(Types.updateProductSizesRequest);
export const updateProductSizesSuccess = createAction<number[]>(
  Types.updateProductSizesSuccess
);
export const updateProductSizesFailure = createAction(
  Types.updateProductSizesFailure
);
export const updateProductGradesRequest = createAction<
  ProductGradesField & { productName: string }
>(Types.updateProductGradesRequest);
export const updateProductGradesSuccess = createAction<string[]>(
  Types.updateProductGradesSuccess
);
export const updateProductGradesFailure = createAction(
  Types.updateProductGradesFailure
);
export const listProcessRequest = createAction<string>(
  Types.listProcessRequest
);
export const listProcessSuccess = createAction<ProductProcess[]>(
  Types.listProcessSuccess
);
export const listProcessFailure = createAction(Types.listProcessFailure);
export const updateProcessRequest = createAction<ProductProcessField>(
  Types.updateProcessRequest
);
export const updateProcessSuccess = createAction(Types.updateProcessSuccess);
export const updateProcessFailure = createAction(Types.updateProcessFailure);
export const deleteProcessRequest = createAction<
  ProductProcessField & { id: string }
>(Types.deleteProcessRequest);
export const deleteProcessSuccess = createAction<ProductProcessField>(
  Types.deleteProcessSuccess
);
export const deleteProcessFailure = createAction(Types.deleteProcessFailure);
export const listLossMoistureRequest = createAction<string>(
  Types.listLossMoistureRequest
);
export const listLossMoistureSuccess = createAction<Moisture[]>(
  Types.listLossMoistureSuccess
);
export const listLossMoistureFailure = createAction(
  Types.listLossMoistureFailure
);
export const createLossMoistureRequest = createAction<MoistureField[]>(
  Types.createLossMoistureRequest
);
export const createLossMoistureSuccess = createAction<MoistureField[]>(
  Types.createLossMoistureSuccess
);
export const createLossMoistureFailure = createAction(
  Types.createLossMoistureFailure
);
export const listLossEstimateRequest = createAction<string>(
  Types.listLossEstimateRequest
);
export const listLossEstimateSuccess = createAction<Estimate[]>(
  Types.listLossEstimateSuccess
);
export const listLossEstimateFailure = createAction(
  Types.listLossEstimateFailure
);
export const createLossEstimateRequest = createAction<EstimateField>(
  Types.createLossEstimateRequest
);
export const createLossEstimateSuccess = createAction(
  Types.createLossEstimateSuccess
);
export const createLossEstimateFailure = createAction(
  Types.createLossEstimateFailure
);
export const deleteLossEstimateRequest = createAction<EstimateField>(
  Types.deleteLossEstimateRequest
);
export const deleteLossEstimateSuccess = createAction<EstimateField>(
  Types.deleteLossEstimateSuccess
);
export const deleteLossEstimateFailure = createAction(
  Types.deleteLossEstimateFailure
);
const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(afterSubmitted, (state) => {
      state.createCategory.isSubmitted = false;
      state.updateCategory.isSubmitted = false;
      state.createProduct.isSubmitted = false;
      state.updateProduct.isSubmitted = false;
      state.getProduct.isSubmitted = false;
      state.process.isSubmitted = false;
      state.lossMoisture.isSubmitted = false;
      state.estimate.isSubmitted = false;
    })
    .addCase(getProductsRequest, (state) => {
      state.listProduct.isLoading = true;
    })
    .addCase(getProductsSuccess, (state, { payload }) => {
      state.listProduct = {
        ...state.listProduct,
        ...payload,
        isLoading: false,
      };
    })
    .addCase(getProductsFailure, (state, { payload }) => {
      state.listProduct.isLoading = false;
    });
  builder
    .addCase(listCategoriesRequest, (state) => {
      state.listCategory.isLoading = true;
    })
    .addCase(listCategoriesSuccess, (state, { payload }) => {
      state.listCategory = {
        ...state.listCategory,
        ...payload,
        isLoading: false,
      };
    })
    .addCase(listCategoriesFailure, (state) => {
      state.listCategory.isLoading = false;
    });
  builder
    .addCase(createCategoryRequest, (state) => {
      state.createCategory.isPending = true;
    })
    .addCase(createCategorySuccess, (state) => {
      state.createCategory.isPending = false;
      state.createCategory.isSubmitted = true;
    })
    .addCase(createCategoryFailure, (state) => {
      state.createCategory.isPending = false;
    });
  builder
    .addCase(updateCategoryRequest, (state) => {
      state.updateCategory.isPending = true;
    })
    .addCase(updateCategorySuccess, (state) => {
      state.updateCategory.isPending = false;
      state.updateCategory.isSubmitted = true;
    })
    .addCase(updateCategoryFailure, (state) => {
      state.updateCategory.isPending = false;
    });
  builder
    .addCase(createProductRequest, (state) => {
      state.createProduct.isPending = true;
    })
    .addCase(createProductSuccess, (state) => {
      state.createProduct.isPending = false;
      state.createProduct.isSubmitted = true;
    })
    .addCase(createProductFailure, (state) => {
      state.createProduct.isPending = false;
    });
  builder
    .addCase(updateProductRequest, (state) => {
      state.updateProduct.isPending = true;
    })
    .addCase(updateProductSuccess, (state) => {
      state.updateProduct.isPending = false;
      state.updateProduct.isSubmitted = true;
    })
    .addCase(updateProductFailure, (state) => {
      state.updateProduct.isPending = false;
    });
  builder
    .addCase(getProductRequest, (state) => {
      state.getProduct.loading = true;
    })
    .addCase(getProductSuccess, (state, { payload }) => {
      state.getProduct.loading = false;
      state.getProduct.data = payload;
    })
    .addCase(getProductFailure, (state) => {
      state.getProduct.loading = false;
    });
  builder
    .addCase(updateProductSizesRequest, (state) => {
      state.getProduct.isPending = true;
    })
    .addCase(updateProductSizesSuccess, (state, { payload }) => {
      state.getProduct.isPending = false;
      state.getProduct.isSubmitted = true;
      if (state.getProduct.data) {
        state.getProduct.data = {
          ...state.getProduct.data,
          sizes: payload,
        };
      }
    })
    .addCase(updateProductSizesFailure, (state) => {
      state.getProduct.isPending = false;
    });
  builder
    .addCase(updateProductGradesRequest, (state) => {
      state.getProduct.isPending = true;
    })
    .addCase(updateProductGradesSuccess, (state, { payload }) => {
      state.getProduct.isPending = false;
      state.getProduct.isSubmitted = true;
      if (state.getProduct.data) {
        state.getProduct.data = {
          ...state.getProduct.data,
          gradesTitle: payload,
        };
      }
    })
    .addCase(updateProductGradesFailure, (state) => {
      state.getProduct.isPending = false;
    });
  builder
    .addCase(listProcessRequest, (state) => {
      state.process.isLoading = true;
    })
    .addCase(listProcessSuccess, (state, { payload }) => {
      state.process.isLoading = false;
      state.process.data = payload;
    })
    .addCase(listProcessFailure, (state) => {
      state.process.isLoading = false;
    })
    .addCase(updateProcessRequest, (state) => {
      state.process.isPending = true;
    })
    .addCase(updateProcessSuccess, (state, { payload }) => {
      state.process.isPending = false;
      state.process.isSubmitted = true;
    })
    .addCase(updateProcessFailure, (state) => {
      state.process.isPending = false;
    })
    .addCase(deleteProcessRequest, (state) => {
      state.process.isPending = true;
    })
    .addCase(deleteProcessSuccess, (state, { payload }) => {
      state.process.isPending = false;
      state.process.isSubmitted = true;
      const process = state.process.data.filter(
        (p) => p.processToProduct !== payload.processToProduct
      );
      state.process.data = process;
    })
    .addCase(deleteProcessFailure, (state) => {
      state.process.isPending = false;
    });
  builder
    .addCase(listLossMoistureRequest, (state) => {
      state.lossMoisture.isLoading = true;
    })
    .addCase(listLossMoistureSuccess, (state, { payload }) => {
      state.lossMoisture.isLoading = false;
      state.lossMoisture.data = payload;
    })
    .addCase(listLossMoistureFailure, (state) => {
      state.lossMoisture.isLoading = false;
    })
    .addCase(createLossMoistureRequest, (state) => {
      state.lossMoisture.isPending = true;
    })
    .addCase(createLossMoistureSuccess, (state, { payload }) => {
      state.lossMoisture.data = payload.map((p) => ({
        currentMoisture: p.currentMoisture.toString(),
        id: (Math.floor(Math.random() * 200) + 1).toString(),
        lossVolume: p.lossVolume.toString(),
        moisture: p.moisture.toString(),
        productName: p.productName,
        targetMoisture: p.targetMoisture.toString(),
        updatedAt: moment().format(),
        updatedBy: '',
      }));
      state.lossMoisture.isPending = false;
      state.lossMoisture.isSubmitted = true;
    })
    .addCase(createLossMoistureFailure, (state) => {
      state.lossMoisture.isPending = false;
    });
  builder
    .addCase(listLossEstimateRequest, (state) => {
      state.estimate.isLoading = true;
    })
    .addCase(listLossEstimateSuccess, (state, { payload }) => {
      state.estimate.isLoading = false;
      state.estimate.data = payload;
    })
    .addCase(listLossEstimateFailure, (state) => {
      state.estimate.isLoading = false;
    })
    .addCase(createLossEstimateRequest, (state) => {
      state.estimate.isPending = true;
    })
    .addCase(createLossEstimateSuccess, (state, { payload }) => {
      state.estimate.isPending = false;
      state.estimate.isSubmitted = true;
    })
    .addCase(createLossEstimateFailure, (state) => {
      state.estimate.isPending = false;
    })
    .addCase(deleteLossEstimateRequest, (state) => {
      state.estimate.isPending = true;
    })
    .addCase(deleteLossEstimateSuccess, (state, { payload }) => {
      state.estimate.isPending = false;
      state.estimate.isSubmitted = true;
      const loss = state.estimate.data.filter((p) => p.id !== payload.id);
      state.estimate.data = loss;
    })
    .addCase(deleteLossEstimateFailure, (state) => {
      state.estimate.isPending = false;
    });
});

export default reducer;
