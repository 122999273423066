import { all, fork, put, call, takeLatest } from 'redux-saga/effects';
import { listCategoriesAPI, listProductsAPI } from 'services';
import * as actions from './index';
import { Types } from './index';
import API from 'services/defaultInstance';
import type { Category, User, Tier, Customer, Lot } from 'models';
import type { Product } from 'models/product.v2';
import { getAllDataWithCursorNextPage } from 'utils/index';

function* listCategories({
  payload,
}: ReturnType<typeof actions.listCategoriesRequest>) {
  try {
    const res: ListRequestDataRes<'products', Category[]> = yield call(
      listCategoriesAPI,
      payload
    );
    yield put(
      actions.listCategoriesSuccess({
        ...res,
      })
    );
  } catch (error) {
    yield put(actions.listCategoriesFailure());
  }
}
function* listProducts({
  payload,
}: ReturnType<typeof actions.listProductRequest>) {
  try {
    const res: ListRequestDataRes<'products', Product[]> = yield call(
      listProductsAPI,
      payload
    );
    yield put(
      actions.listProductSuccess({
        ...res,
      })
    );
  } catch (error) {
    yield put(actions.listProductFailure());
  }
}
function* batchListUsers() {
  try {
    const data: User[] = yield getAllDataWithCursorNextPage<User[], 'users'>({
      httpRequest: (queries) => API.get(`/v1/admin/tier/users${queries}`),
      keyResponse: 'users',
    });
    yield put(
      actions.batchListUserSuccess({
        users: data,
        nextPageToken: '',
      })
    );
  } catch (error) {
    yield put(actions.batchListUserFailure());
  }
}
function* batchListTiers() {
  try {
    const data: Tier[] = yield getAllDataWithCursorNextPage<Tier[], 'tiers'>({
      httpRequest: (queries) => API.get(`v1/admin/tier/tiers${queries}`),
      keyResponse: 'tiers',
    });
    yield put(
      actions.batchListTierSuccess({
        tiers: data,
        nextPageToken: '',
      })
    );
  } catch (error) {
    yield put(actions.batchListTierFailure());
  }
}
function* listCustomers() {
  try {
    const data: Customer[] = yield getAllDataWithCursorNextPage<
      Customer[],
      'customers'
    >({
      httpRequest: (queries) => API.get(`/v1/admin/tier/customers${queries}`),
      keyResponse: 'customers',
    });
    yield put(
      actions.listCustomerSuccess({
        customers: data,
        nextPageToken: '',
      })
    );
  } catch (error) {
    yield put(actions.listCustomerFailure());
  }
}
function* batchListLots({
  payload,
}: ReturnType<typeof actions.listLotsRequest>) {
  try {
    const data: Lot[] = yield getAllDataWithCursorNextPage<Lot[], 'lots'>({
      httpRequest: (queries) =>
        API.get(
          `/v1/inventory/lots${queries}&tier=${payload.tier}&status=READY`
        ),
      keyResponse: 'lots',
      maxDataLength: 5000,
      pageSize: 250,
    });
    yield put(
      actions.listLotsSuccess({
        lots: data,
        nextPageToken: '',
      })
    );
  } catch (error) {
    yield put(actions.listLotsFailure());
  }
}
function* watchListCategories() {
  yield takeLatest(Types.listCategoriesRequest, listCategories);
}
function* watchListProducts() {
  yield takeLatest(Types.listProductRequest, listProducts);
}
function* watchBatchListUsers() {
  yield takeLatest(Types.batchListUserRequest, batchListUsers);
}
function* watchBatchListTiers() {
  yield takeLatest(Types.batchListTierRequest, batchListTiers);
}
function* watchBatchListCustomer() {
  yield takeLatest(Types.listCustomerRequest, listCustomers);
}
function* watchBatchListLots() {
  yield takeLatest(Types.listLotsRequest, batchListLots);
}
function* saga() {
  yield all([
    fork(watchListCategories),
    fork(watchListProducts),
    fork(watchBatchListUsers),
    fork(watchBatchListTiers),
    fork(watchBatchListCustomer),
    fork(watchBatchListLots),
  ]);
}
export default saga;
