import { all, fork, put, call, takeLatest, select } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { addAlert } from 'store/notify';
import API, { CancelToken, Canceler } from 'services/defaultInstance';
import * as actions from './index';
import {
  // GetLotsResponse,
  GetStocksResponse,
  StocksQueries,
  DefaultQueries,
} from 'models';
import {
  findErrorToData,
  joinParamsToStringHttpRequest,
  mappingParamsToPayload,
  setPageInfo,
} from 'utils';
import { RootState } from 'store/index';
import moment from 'moment';

let cancels: Canceler[] = [];

const getStocksAPI = (query: string) =>
  API.get(`/v1/inventory/stocks?${query}`, {
    cancelToken: new CancelToken((c) => cancels.push(c)),
  });
const createTransFormAPI = (stockId: string) =>
  API.post(`/v2/inventory/stocks/process`, {
    stockId,
  });

// const getLotsAPI = (query: string) => API.get(`/v1/inventory/lots?${query}`);
type GetStocksRequest = ReturnType<typeof actions.getStocksRequest>;
function* getStocks({ payload }: GetStocksRequest) {
  const {
    auth,
    inventoryStocks: { stocksList },
  }: RootState = yield select((state) => state);

  try {
    const queries: string = yield joinParamsToStringHttpRequest({
      values: {
        ...payload,
        pageSize: payload.pageSize || stocksList.pageSize,
        tier: auth.accept.tier?.name || '',
      },
      keysParams: [
        'pageToken',
        'purchasedAt',
        'pageSize',
        'tier',
        'parentProduct',
        'subProduct',
        'product',
        'startedAt',
        'endedAt',
      ],
    });
    const { data }: GetStocksResponse = yield call(getStocksAPI, queries);
    const page = setPageInfo(
      {
        page: stocksList.page,
        pageSize: stocksList.pageSize,
        pageTokens: stocksList.pageTokens,
      },
      {
        page: payload.page,
        pageSize: payload.pageSize,
        nextPageToken: data.nextPageToken,
      }
    );
    const dataPayload: actions.GetStocksSuccess = {
      ...page,
      data: data.stocks,
    };
    yield put(actions.getStocksSuccess(dataPayload));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.getStocksFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
type StocksOnChangePage = ReturnType<typeof actions.stocksOnChangePage>;
function* stocksOnChangePage({ payload }: StocksOnChangePage) {
  const {
    inventoryStocks: { stocksList },
  }: RootState = yield select((state) => state);
  const { page, pageTokens } = stocksList;
  let cloneFilters: StocksQueries & DefaultQueries = {
    ...payload,
  };
  const prevPage = payload.nextOrPrev && payload.nextOrPrev === 'prev';
  const pageToken = pageTokens[prevPage ? page - 3 : page - 1];
  cloneFilters.page = prevPage ? page - 1 : page + 1;
  const prevToFirstPage = page - 2 === 0 && prevPage;
  if (pageToken && !prevToFirstPage) {
    cloneFilters.pageToken = pageToken;
  }
  yield put(actions.getStocksRequest(cloneFilters));
}
type StocksOnChangePageSize = ReturnType<typeof actions.stocksOnChangePageSize>;
function* stocksOnChangePageSize({ payload }: StocksOnChangePageSize) {
  yield put(actions.getStocksRequest({ ...payload }));
}
function* createTransForm() {
  const { transForm }: actions.InitialState = yield select(
    (state: RootState) => state.inventoryStocks
  );
  if (!transForm.stock) return;
  try {
    yield call(createTransFormAPI, transForm.stock.id);
    yield put(actions.createTransFormSuccess());
    yield put(
      addAlert({
        message: 'ຍ້າຍໄປແປຮູບສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(actions.getStocksRequest({}));
  } catch (error: any) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.createTransFormFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
// function* getLots() {
//   const {
//     auth: { accept },
//     inventoryStocks,
//   }: RootState = yield select((state: RootState) => state);
//   const query = `tier=${accept.tier?.name}&pageSize=250&status=READY`;
//   if (inventoryStocks.moveStockToLot.lots.length > 0) {
//     yield put(actions.getLotsSuccess(inventoryStocks.moveStockToLot.lots));
//     return;
//   }
//   try {
//     const { data }: GetLotsResponse = yield call(getLotsAPI, query);
//     const today = moment().format('YYYY-MM-DD');
// const lotIsBefore = data.lots.filter((lot) => {
//   const endAt = moment(lot.endAt).format('YYYY-MM-DD');
//   const data = moment(today).isSameOrBefore(endAt);
//   return data;
// });
//     yield put(actions.getLotsSuccess(lotIsBefore));
//   } catch (error) {
//     const errorData = findErrorToData({ error: error });
//     yield put(actions.getLotsFailure(errorData?.message || ''));
//     if (errorData && errorData.serviceType === 'snackbar') {
//       yield put(addAlert(errorData));
//     }
//   }
// }
type MoveStockToLotRequest = ReturnType<typeof actions.moveStockToLotRequest>;
function* moveStockToLot({ payload }: MoveStockToLotRequest) {
  const { moveStockToLot }: actions.InitialState = yield select(
    (state: RootState) => state.inventoryStocks
  );
  try {
    yield call(API.post, `/v2/inventory/stocks/add-to-lot`, {
      lotId: payload.lotId,
      stockId: moveStockToLot.stock?.id || '',
    });
    yield put(actions.moveStockToLotSuccess());
    yield put(
      addAlert({
        message: 'ເພີ່ມເຂົ້າລ໋ອກສຳເລັດແລ້ວ',
        serviceType: 'snackbar',
        type: 'success',
      })
    );
    yield put(actions.getStocksRequest({}));
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.moveStockToLotFailure(errorData?.message || ''));
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* exportCsv({ payload }: ReturnType<typeof actions.exportCsvRequest>) {
  const {
    auth: { accept },
  }: RootState = yield select((state) => state);
  try {
    const queries: string = yield mappingParamsToPayload({
      values: {
        ...payload,
        tier: accept.tier?.name || '',
      },
      keysParams: [
        'purchasedAt',
        'tier',
        'parentProduct',
        'subProduct',
        'product',
        'startedAt',
        'endedAt',
      ],
    });
    const { data } = yield call(
      API.post,
      `/v1/inventory/stocks-exportCsv`,
      queries
    );
    const blob = new Blob(['\uFEFF' + data], {
      type: 'text/csv; charset=utf-8',
    });
    yield saveAs(
      blob,
      `ລາຍການຮັບຊື້ສິນຄ້າ${moment().format('DD/MM/YYYY')}.csv`
    );
    yield put(actions.exportCsvSuccess());
  } catch (error) {
    const errorData = findErrorToData({ error: error });
    yield put(actions.exportCsvFailure());
    if (errorData && errorData.serviceType === 'snackbar') {
      yield put(addAlert(errorData));
    }
  }
}
function* watchCancelRequestAPI() {
  yield takeLatest(actions.Types.cancelRequestAPI, function* () {
    yield cancels.forEach((c) => c());
    yield (cancels = []);
  });
}
function* watchGetStocks() {
  yield takeLatest(actions.Types.getStocksRequest, getStocks);
}
function* watchStocksOnChangePage() {
  yield takeLatest(actions.Types.stocksOnChangePage, stocksOnChangePage);
}
function* watchGetStocksOnChangePageSize() {
  yield takeLatest(
    actions.Types.stocksOnChangePageSize,
    stocksOnChangePageSize
  );
}
function* watchExportCsv() {
  yield takeLatest(actions.Types.exportCsvRequest, exportCsv);
}
function* watchCreateTransForm() {
  yield takeLatest(actions.Types.createTransFormRequest, createTransForm);
}
// function* watchGetLots() {
//   yield takeLatest(actions.Types.openMoveStockToLotDialog, getLots);
// }
function* watchMoveStockToLot() {
  yield takeLatest(actions.Types.moveStockToLotRequest, moveStockToLot);
}
function* saga() {
  yield all([
    fork(watchCancelRequestAPI),
    fork(watchGetStocks),
    fork(watchStocksOnChangePage),
    fork(watchGetStocksOnChangePageSize),
    fork(watchCreateTransForm),
    // fork(watchGetLots),
    fork(watchMoveStockToLot),
    fork(watchExportCsv),
  ]);
}
export default saga;
